import React, { useEffect, useState } from 'react';

import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { Entity } from '@backstage/catalog-model';
import { useApi } from '@backstage/core-plugin-api';

import * as T from './types';
import {
  Apigee,
  Etl,
  Kubernetes,
  StaticFrontend,
  Serverless,
} from '../../../Contents/EnvironmentCard';

export const EnvironmentsConfig = ({
  envName,
  envType,
  ciEnvironment,
  ciBuildPlatform
}:T.EnvProps) => {
  const catalogApi = useApi(catalogApiRef);
  const [result, setResult] = useState<Entity | null>(null);

  const getEnvironment = async (environmentName: any) => {
    const request = {
      filter: {
        kind: 'Environment',
        'metadata.name': environmentName,
      },
    };
    const response = await catalogApi.getEntities(request);
    return response.items[0];
  };

  useEffect(() => {
    getEnvironment(envName).then(result => {
      setResult(result);
    });
  }, []);

  if (result === null) {
    return <div>Loading...</div>;
  }

  const envTypeToComponent = {
    'api-gateway': Apigee,
    'etl': Etl,
    'kubernetes': Kubernetes,
    'static-frontend': StaticFrontend,
    'serverless': Serverless,
  };

  if (envType) {
    const Component = envTypeToComponent[envType];
    
    if (Component) {
      if (envType === 'kubernetes') { 
        return <Component result={result} ciEnvironment={ciEnvironment} ciBuildPlatform={ciBuildPlatform}/>;
      } else {
        return <Component result={result}/>;
      }
    } else {
      throw new Error(`Environment type não encontrado`);
    }
  }

  throw new Error('Environment não encontrado');
};
