/* eslint-disable react-hooks/exhaustive-deps */
/*
 * Copyright 2021 Spotify AB
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import {
  createScaffolderFieldExtension,
  FieldExtensionComponentProps,
} from '@backstage/plugin-scaffolder-react';
import { FieldValidation } from '@rjsf/utils';
import {
  TextField,
  FormControl,
  Autocomplete,
  CircularProgress,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
  githubAuthApiRef,
  useApi,
  configApiRef,
} from '@backstage/core-plugin-api';
import axios, { CancelTokenSource } from 'axios';
import { debounce } from 'lodash';

export const parseGithubRepoUrl = (input: string) => {
  const githubUrlRegex = new RegExp(
    /((git|ssh|http(s)?)(:(\/\/)?)|(git@))(github.com)[/:](?<org>[\w\.@\:\-~]+)\/(?<repo>[\w@\:\-~]+)/i,
  );
  const urlMatch = githubUrlRegex.exec(input);
  if (
    urlMatch &&
    urlMatch.length > 0 &&
    urlMatch.groups?.org &&
    urlMatch.groups?.repo
  ) {
    return { org: urlMatch.groups.org, repo: urlMatch.groups.repo };
  }
  return null;
};

type GithubRepo = {
  name: string;
  url: string;
  nameWithOwner: string;
  owner: {
    login: string;
  };
  defaultBranchRef: {
    name: string;
  };
};

export const GBTechRepositoryPicker = ({
  onChange,
  schema: { title = 'Repository', description = 'Github repository' },
  required,
  uiSchema,
  formData,
}: FieldExtensionComponentProps<GithubRepo>) => {
  const uiOptions = 'ui:options';

  const config = useApi(configApiRef);

  const org = config.get('github.orgName') as string;
  const allowNewValue = uiSchema[uiOptions]?.allowNewValue as boolean | false;

  const githubAuth = useApi(githubAuthApiRef);
  const [githubToken, setGithubToken] = useState(String);
  const [options, setOptions] = React.useState<any[]>();
  const [value, _] = React.useState(formData || '');
  const [inputValue, setInputValue] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const cancelToken = React.useRef<CancelTokenSource>();

  useEffect(() => {
    const fetchGithubToken = async () => {
      const accessToken = await githubAuth.getAccessToken([
        'user',
        'public_repo',
        'repo',
        'repo_deployment',
        'repo:status',
        'read:org',
      ]);

      setGithubToken(accessToken);
    };

    async function fetchRepos() {
      if (!inputValue || inputValue.trim().length <= 2 || !githubToken) {
        return;
      }

      let queryString = org ? `org:${org} fork:true ${inputValue}` : inputValue;
      const parsedUrl = parseGithubRepoUrl(inputValue);
      if (parsedUrl) {
        queryString = `org:${parsedUrl.org} fork:true ${parsedUrl.repo}`;
      }

      setLoading(true);

      if (cancelToken?.current) {
        cancelToken.current.cancel();
      }

      cancelToken.current = axios.CancelToken.source();

      const graphqlQuery = {
        query: `query myOrgRepos($queryString: String!) {
                  search(query: $queryString, type: REPOSITORY, first: 10) {
                    repositoryCount
                      edges {
                        node {
                          ... on Repository {
                            name
                            url
                            nameWithOwner
                            owner {
                              login
                            }
                            defaultBranchRef{
                              name
                            }
                        }
                      }
                    }
                  }
              }`,
        variables: {
          queryString,
        },
      };
      try {
        const response = await axios.post(
          'https://api.github.com/graphql',
          graphqlQuery,
          {
            headers: {
              Authorization: `Bearer ${githubToken}`,
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            cancelToken: cancelToken.current?.token,
          },
        );

        const repos = response.data.data.search.edges.map((e: any) => e.node);
        if (repos && repos.length > 0) {
          setOptions(repos);
        }

        if (parsedUrl) {
          const _nameWithOwner = `${parsedUrl.org}/${parsedUrl.repo}`;
          const _option = options?.find(
            repo => repo.nameWithOwner === _nameWithOwner,
          );

          if (_option) {
            onChange(_option);
            setInputValue(_nameWithOwner);
          }
        }

        setLoading(false);
      } catch (error) {
        if (axios.isCancel(error)) {
          return;
        }
        setLoading(false);
        throw error;
      }
    }
    const fetchReposDebounced = debounce(fetchRepos, 300);
    fetchReposDebounced();

    if (!githubToken) {
      fetchGithubToken();
    }

    if (!inputValue && formData) {
      onChange(undefined);
    }
  }, [formData, githubAuth, githubToken, inputValue]);

  const onSelect = (__: any, selectedValue: any) => {
    let _value = selectedValue ?? '';
    let _nameWithOwner = selectedValue;

    const parsedUrl = parseGithubRepoUrl(inputValue);

    if (parsedUrl) {
      _nameWithOwner = `${parsedUrl.org}/${parsedUrl.repo}`;
    }

    if (typeof _nameWithOwner === 'string') {
      _value = options?.find(repo => repo.nameWithOwner === _nameWithOwner);
    }

    onChange(_value);
    setInputValue(_value?.nameWithOwner ?? '');
  };

  return (
    <FormControl margin="normal" required={required}>
      <Autocomplete
        id="gbtech-repopicker-ac"
        value={value}
        onChange={onSelect}
        autoSelect
        inputValue={inputValue}
        data-testid="autocomplete"
        onInputChange={(_event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        options={options || []}
        getOptionLabel={option => option.nameWithOwner ?? ''}
        freeSolo={!allowNewValue}
        renderInput={params => (
          <TextField
            {...params}
            id="gbtech-repopicker-input"
            value={inputValue}
            label={title}
            margin="normal"
            helperText={description}
            variant="outlined"
            required={required}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </FormControl>
  );
};

export const GBTechRepositoryPickerFieldExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    component: GBTechRepositoryPicker,
    name: 'GBTechRepositoryPicker',
    validation: (data: any, fieldValidation: FieldValidation) => {
      if (!data) {
        fieldValidation.addError('Selecione um repositório válido');
      }
    },
  }),
);
