import axios from 'axios';
import { StepsFormData } from '../../pages/OnCall/DialogStepper/types';

import * as T from './types';
import { addHours, formatISO } from 'date-fns';
import { OverrideFormData } from '../../pages/OnCall/OverrideDialog/types';

const contentTypeInfo = 'application/json';

const getOpsgenieTeams = async (
  baseUrl: string,
  email: string,
  token?: string,
) => {
  const { data } = await axios.get<any>(
    `${baseUrl}opsgenie/user/${email}/teams`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': contentTypeInfo,
      },
    },
  );

  return data;
};

const getOpsgenieUsersByEmail = async (
  baseUrl: string,
  email: string,
  token?: string,
) => {
  const { data } = await axios.get<any>(
    `${baseUrl}opsgenie/users?query=${email}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': contentTypeInfo,
      },
    },
  );

  return data;
};

const getLastSaturday = (refDate: Date) => {
  const day = refDate.getDay();
  const lastSaturday = refDate.getDate() - (day + 1);
  return new Date(refDate.setDate(lastSaturday));
};

const addOrEditOpsgenieTeam = async (
  orgName: string,
  payload: StepsFormData,
  token?: string,
) => {
  const members = payload?.members?.map(member => ({
    email: member.email,
  }));

  const rotationMembers = payload?.rotationMembers?.map(member => ({
    email: member.email,
  }));

  let startHour;
  if (payload.nightShiftStartHour !== undefined) {
    startHour = addHours(
      new Date(0, 0, 0, payload.nightShiftStartHour, 0, 0),
      -12,
    );
  }
  const startDate =
    payload?.startDate ||
    getLastSaturday(new Date()).setHours(startHour?.getHours() || 0, 0, 0, 0);

  const fixedEscalationRule = {
    condition: 'if-not-acked',
    notifyType: 'default',
    delay: 0,
    recipient: {
      type: 'schedule',
      id: 'default',
    },
  };

  const escalationRules = payload.escalationRules?.map(rule => ({
    condition: rule.condition,
    notifyType: 'default',
    delay: rule.delay.timeAmount,
    recipient: {
      type: rule.recipient.type,
      id: rule.recipient.email,
    },
  }));

  if (escalationRules && escalationRules[0]?.recipient?.type === 'schedule') {
    escalationRules.shift();
  }

  escalationRules?.unshift(fixedEscalationRule);

  const body = {
    event_type: 'opsgenie',
    client_payload: {
      workflow: 'team',
      team_payload: {
        VS: payload.domain,
        template: 'opsgenie-default',
        name: payload.name,
        description: payload.description,
        startDate: formatISO(startDate),
        startHour: startHour?.getHours(),
        endHour: payload.nightShiftStartHour,
        routingRules: {
          notifyAllOnOfficeHours: payload.notifyAllOnBusinessHours,
        },
        members: members,
        escalationPaths: {
          onCall: {
            rules: escalationRules,
          },
        },
        schedules: {
          default: {
            members: rotationMembers,
          },
        },
      },
    },
  };

  const { data } = await axios.post(
    `https://api.github.com/repos/${orgName}/sre-monitoring-resources/dispatches`,
    body,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'X-GitHub-Api-Version': '2022-11-28',
        Accept: 'application/vnd.github+json',
      },
    },
  );

  return data;
};

const getDomainByTeamName = (domainList: string[], teamName: string) => {
  return domainList.find(domain => teamName.includes(domain)) || '';
};

const deleteOpsgenieTeam = async (
  orgName: string,
  teamName: string,
  domain: string,
  token?: string,
) => {
  const body = {
    event_type: 'opsgenie',
    client_payload: {
      workflow: 'team',
      team_payload: {
        VS: domain,
        template: 'opsgenie-default',
        name: teamName,
        action: 'del',
      },
    },
  };

  const { data } = await axios.post(
    `https://api.github.com/repos/${orgName}/sre-monitoring-resources/dispatches`,
    body,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'X-GitHub-Api-Version': '2022-11-28',
        Accept: 'application/vnd.github+json',
      },
    },
  );

  return data;
};

const getOpsgenieScheduleMembers = async (
  baseUrl: string,
  scheduleId: string,
  startDate: Date,
  token?: string,
) => {
  const { data } = await axios.get<any>(
    `${baseUrl}opsgenie/schedule/${scheduleId}/oncall/${formatISO(startDate)}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': contentTypeInfo,
      },
    },
  );

  return data;
};

const addScheduleOverride = async (
  baseUrl: string,
  scheduleId: string,
  formData: OverrideFormData,
  token?: string,
) => {
  if (formData.startDateTime === null || formData.endDateTime === null) {
    throw new Error('Por favor, preencha todos os campos');
  }

  const payload = {
    startDate: formatISO(formData.startDateTime),
    endDate: formatISO(formData.endDateTime),
    user: formData.member?.email,
    rotations: ['weekend', 'weekday'],
  };

  const { data } = await axios.post<any>(
    `${baseUrl}opsgenie/schedule/${scheduleId}/override`,
    payload,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': contentTypeInfo,
      },
    },
  );

  return data;
};

const getRequestHistory = async (orgName: string, token?: string) => {
  const isDevMode = orgName === 'gbsandbox';

  const { data } = await axios.get<any>(
    `https://api.github.com/repos/${orgName}/sre-monitoring-resources/actions/workflows/apply.yaml/runs?per_page=50&page=1&branch=main&event=push&actor=${
      isDevMode ? 'msuchy' : 's-azdevops'
    }`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': contentTypeInfo,
      },
    },
  );

  return data;
};

const isUserTechlead = async (
  baseUrl: string,
  email?: string,
  token?: string,
) => {
  const { data } = await axios.get<T.IsUserTechlead>(
    `${baseUrl}slack-api/isUserTechlead/${email}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': contentTypeInfo,
      },
    },
  );

  return data;
};

export {
  getOpsgenieTeams,
  getOpsgenieUsersByEmail,
  addOrEditOpsgenieTeam,
  getOpsgenieScheduleMembers,
  getRequestHistory,
  isUserTechlead,
  addScheduleOverride,
  deleteOpsgenieTeam,
  getLastSaturday,
  getDomainByTeamName,
};
