import { makeStyles } from "@material-ui/core";
import { alpha } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  contDetails: {
    marginRight: '30px',
  },
  titleProperty: {
    fontSize: '10px',
  },
  experimental: {
    margin: '0px 5px 5px 0px',
    backgroundColor: alpha(theme.palette.status.running, 0.6),
    border: `1px solid ${theme.palette.status.running}`,
    padding: '0px 8px',
    borderRadius: '25px',
    fontSize: '12px',
  },
  resultproperty: {
    padding: '0px 8px',
    borderRadius: '25px',
    fontSize: '12px',
    display: 'flex',
    alignItems: 'center',
    margin:'14px 5px 5px 0',
  },
  production: {
    backgroundColor: alpha(theme.palette.status.ok, 0.6),
    border: `1px solid ${theme.palette.status.ok}`,
  },
  deprecated: {
    backgroundColor: alpha(theme.palette.status.error, 0.6),
    border: `1px solid ${theme.palette.status.error}`,
  },
  enable: {
    backgroundColor: alpha(theme.palette.status.ok, 0.6),
    border: `1px solid ${theme.palette.status.ok}`,
  },
  disabled: {
    backgroundColor: alpha(theme.palette.status.error, 0.6),
    border: `1px solid ${theme.palette.status.error}`,
  },
  icon:{
    fontSize: '16px',
    fontWeight: 'bold',
    marginRight: '5px',
  },
}))