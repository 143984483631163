
import { createApiRef, DiscoveryApi, IdentityApi } from '@backstage/core-plugin-api';
import axios from 'axios';
import * as t from 'io-ts';

const DEFAULT_PROXY_PATH_BASE: string = '/github-iam';

export interface GitHubIAM {
  getUserByGithubLogin(githubLogin: string): Promise<GitHubIAMUserData | undefined>;
}

export interface GitHubIAMResponse {
  status: string;
  message: GitHubIAMUserData
}

export const Message = t.type({
  user: t.type({
    id: t.number,
    name: t.string,
    mail: t.string,
    github_login: t.string,
    is_active: t.boolean
  }),
  orgs: t.array(t.type({
    github_org: t.string,
    is_active: t.boolean
  }))
});

export type GitHubIAMUserData = t.TypeOf<typeof Message>;

export const GitHubIAMAPIRef = createApiRef<GitHubIAM>({
  id: 'plugin.githubiam.service',
});

export type Options = {
  discoveryApi: DiscoveryApi;
  identityApi: IdentityApi
};

export class GithubIAMAPIClient implements GitHubIAM {
  private readonly discoveryApi: DiscoveryApi;
  private readonly proxyPathBase: string;
  private readonly identityApi: IdentityApi

  constructor(options: Options) {
    this.discoveryApi = options.discoveryApi;
    this.proxyPathBase = DEFAULT_PROXY_PATH_BASE;
    this.identityApi = options.identityApi
  }

  private async getProxyUrl() {
    return await this.discoveryApi.getBaseUrl('proxy');
  }

  async getUserByGithubLogin(githubLogin: string): Promise<GitHubIAMUserData | undefined> {
    try {
      const proxyUrl = await this.getProxyUrl();
      const credentials = await this.identityApi.getCredentials()
      const url = `${proxyUrl}${this.proxyPathBase}/member/${githubLogin}`;
      const { data } = await axios.get<GitHubIAMResponse>(url, {
        headers: {
          'Authorization': `Bearer ${credentials.token}`
        }
      });
      return data.message;
    } catch (error) {
      return undefined;
    }
  }
}