import { InputAdornment, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';

import * as T from './types';
import { useApi } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { Autocomplete } from '@mui/material';

export function TeamInformationStep({ onChange }: T.TeamInformationStepProps) {
  const catalogApi = useApi(catalogApiRef);

  const [domainList, setDomainList] = useState<string[]>([]);
  const [stepData, setStepData] = useState<T.TeamInformationFormData>({
    name: '',
    description: '',
    domain: '{domain}',
    isFilled: false,
  });
  const [nameError, setNameError] = useState(false);

  const checkIfFilled = () => {
    return Boolean(stepData.name && stepData.description && stepData.domain);
  };

  const handleChange = (value: string, field: 'description' | 'domain') => {
    setStepData({
      ...stepData,
      [field]: value,
    });
  };

  const handleNameChange = (value: string, validity: any) => {
    if (value === null) {
      return;
    }
    if (validity.valid) {
      setNameError(false);
      setStepData({
        ...stepData,
        ['name']: value,
      });
    } else {
      setNameError(true);
      setStepData({
        ...stepData,
        ['name']: '',
      });
    }
  };

  const getDomainList = async () => {
    const request = {
      filter: {
        kind: ['Domain'],
        'metadata.gbtechMultiaccount': 'true',
      },
    };

    const { items } = await catalogApi.getEntities(request);

    const filteredDomains = items.map(item => item.metadata.name);
    setDomainList(filteredDomains);
  };

  useEffect(() => {
    onChange({ ...stepData, isFilled: checkIfFilled() });
  }, [stepData]);

  useEffect(() => {
    getDomainList();
  }, []);

  return (
    <>
      <Autocomplete
        options={domainList}
        getOptionLabel={option => option}
        noOptionsText="Not found"
        onChange={(_, changedValue) =>
          changedValue && handleChange(changedValue, 'domain')
        }
        renderInput={params => (
          <TextField
            {...params}
            type="text"
            label="Domain / Value Stream / Chapter"
            variant="outlined"
            fullWidth
            placeholder="Team's Domain / Value Stream / Chapter"
          />
        )}
      />
      <TextField
        type="text"
        label="Name"
        variant="outlined"
        fullWidth
        placeholder="Team name"
        required
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              ops-{stepData.domain}-
            </InputAdornment>
          ),
        }}
        inputProps={{
          pattern: '[a-z0-9_]+',
        }}
        error={nameError}
        helperText={
          nameError ? '(Somente letras minúsculas, números e sublinhados)' : ''
        }
        onChange={e => handleNameChange(e.target.value, e.target.validity)}
      />
      <TextField
        type="text"
        label="Description"
        variant="outlined"
        fullWidth
        placeholder="Team description"
        required
        onChange={e => handleChange(e.target.value, 'description')}
      />
    </>
  );
}
