export default function flatten(
  object: object,
  rootProperty: string | undefined,
): any {
  const finalObject: any = {};
  for (const [key, value] of Object.entries(object)) {
    if (typeof value === 'object') {
      const innerKey = rootProperty ? `${rootProperty}.${key}` : key;
      Object.assign(finalObject, flatten(value, innerKey));
    } else {
      const property = rootProperty ? `${rootProperty}.${key}` : key;
      finalObject[property] = value.toString();
    }
  }
  return finalObject;
}
