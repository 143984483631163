import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => {
  return {
    ViewContainer: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      width: '100%',
      gap: theme.spacing(1),

      '&.disabled': {
        pointerEvents: 'none',
        opacity: 0.5,
      },
    },
    ConditionsLine: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      margin: '0px',
      height: 36,
    },
    Alert: {
      margin: '0px',
      paddingLeft: theme.spacing(1),
      width: '100%',
    },
    Condition: {
      minWidth: 50,
      marginRight: theme.spacing(1),
      height: '22px',
      borderRadius: '4px',
      display: 'flex',
      justifyContent: 'center',
      border: `1px solid ${
        theme.palette.mode === 'dark' ? '#FFFFFF33' : '#00000010'
      }`,
      background: theme.palette.mode === 'dark' ? '#FFFFFF1A' : '#00000008',
      margin: '0px',
    },
    Action: {
      margin: theme.spacing(0, 1),
      width: 47,
    },
  };
});
