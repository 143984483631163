/*
 * Copyright 2021 Spotify AB
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import React from 'react';
import { GetEntitiesRequest } from '@backstage/catalog-client';
import { CATALOG_FILTER_EXISTS } from '@backstage/plugin-catalog-react';
import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import {
  createScaffolderFieldExtension,
  FieldExtensionComponentProps
} from '@backstage/plugin-scaffolder-react';

import { TextField, Autocomplete, Box, Alert, Typography } from '@mui/material';

import flatten from '../../../../utils/flatten/flatten';
import { useGBTechEntityPicker } from './useGBTechEntityPicker';

export const replaceCatalogExists = (object: any) => {
  if (object === null) return object;
  switch (typeof object) {
    case 'string':
      if (object === 'CATALOG_FILTER_EXISTS') object = CATALOG_FILTER_EXISTS;
      break;
    case 'object':
      if (object instanceof Array) {
        for (let i = 0; i < object.length; i++) {
          object[i] = replaceCatalogExists(object[i]);
        }
      } else {
        for (let i in object) {
          object[i] = replaceCatalogExists(object[i]);
        }
      }
      break;
  }

  return object;
};

const options = 'ui:options';

export const GBTechEntityPicker = ({
  onChange,
  schema,
  required,
  uiSchema,
  rawErrors,
  formData,
  formContext
}: FieldExtensionComponentProps<string>) => {
  const [selectedValue, setSelectedValue] = React.useState<string | unknown>(formData);
  const allowedKinds = uiSchema[options]?.allowedKinds as string[];
  const additionalFilters = uiSchema[options]?.filters as object[] | undefined;
  const defaultKind = uiSchema[options]?.defaultKind as string;
  const allowNewValue = uiSchema[options]?.allowNewValue as boolean | false;
  const exclusionEntities = uiSchema[options]?.exclusionList as string[];
  const displayCustomOptions = uiSchema[options]?.displayCustomOptions as boolean;
  const { system, domain: domainCatalog } =
    formContext?.formData?.catalogInfo ?? formContext?.formData ?? {};
  const domain = domainCatalog ?? sessionStorage.getItem('Domain') ?? '';

  schema.title = schema.title || 'Entity';

  schema.description = schema.description || 'An entity from the catalog';

  let request: GetEntitiesRequest | undefined = undefined;
  let filters: object | undefined = undefined;

  if (additionalFilters) {
    filters = flatten(additionalFilters, undefined);
  }

  filters = replaceCatalogExists(filters);

  if (allowedKinds) {
    request = {
      filter: {
        kind: allowedKinds,
        ...filters
      }
    };
  }
  const { entities, loading } = useGBTechEntityPicker({
    kind: defaultKind,
    exclusionEntities,
    filters: request
  });

  const onSelect = (___: any, value: any | null) => {
    if (defaultKind?.toLowerCase() === 'domain') {
      sessionStorage.setItem('Domain', value?.domain ?? '');
      formContext.formData['domain'] = value?.domain; // need to create value in context form and show value in review
      value = value?.system;
    }
    onChange(value ?? undefined);
    setSelectedValue(value ?? null);
  };

  const hasBTPAlert = () => {
    const isDomain = allowedKinds && allowedKinds[0] === 'Domain';
    const isSap = selectedValue === 'sap';

    return isDomain && isSap;
  };

  const renderOption = (
    props: React.HTMLAttributes<HTMLLIElement> & { key: any },
    option: { system: string; domain: string }
  ) => {
    const { key, ...optionProps } = props;
    return (
      <li key={key} {...optionProps}>
        <Box>
          <Typography component="p"> {option.system}</Typography>
          <Typography variant="caption" color="textSecondary">
            Domain: {option.domain}
          </Typography>
        </Box>
      </li>
    );
  };
  const customProps = {
    ...(displayCustomOptions
      ? {
          renderOption,
          getOptionLabel: (option: any) => `${option.system} (${option.domain})`
        }
      : {
          getOptionLabel: (option: any) => (typeof option === 'string' ? option : '')
        }),
    ...(displayCustomOptions && system && { value: { domain, system } }),
    ...(!displayCustomOptions && { value: selectedValue ?? null })
  };

  return (
    <>
      <Autocomplete
        id="gbtech-entitypicker-ac"
        loading={loading}
        onChange={onSelect}
        options={entities}
        freeSolo={allowNewValue}
        data-testid="autocomplete-entityPicker"
        renderInput={(params) => (
          <TextField
            {...params}
            id="gbtech-entitypicker-input"
            error={rawErrors?.length > 0 && !formData}
            label={schema.title}
            margin="normal"
            helperText={schema.description}
            variant="outlined"
            required={required}
          />
        )}
        {...customProps}
      />
      {hasBTPAlert() && <Alert severity="info">Esta opção será implantada em BTP</Alert>}
    </>
  );
};

export const GBTechEntityPickerFieldExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    component: GBTechEntityPicker,
    name: 'GbEntityPicker'
  })
);
