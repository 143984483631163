import React from 'react';

import DoneIcon from '@mui/icons-material/Done';
import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt';
import SettingsIcon from '@mui/icons-material/Settings';

import { useStyles } from './styles';
import * as T from './types';


export const RenderStatus = ({ resultProperty, title }:T.RenderStatusProps) => {
  const S = useStyles();
  
  const enviroments: Record<string, T.Enviroment> = {
    experimental: {
       style: S.experimental,
       icon: <SettingsIcon className={S.icon} />
    },
    production: {
      style: S.production,
      icon: <DoneIcon className={S.icon} />
    },
    deprecated: {
     style: S.deprecated,
     icon: <DoNotDisturbAltIcon className={S.icon} />
    },
    habilitado:{
      style: S.enable,
      icon: <DoneIcon className={S.icon} />
    },
    desabilitado: {
      style: S.disabled,
      icon: <DoNotDisturbAltIcon className={S.icon} />
    },
  }
  
  const  enviroment = enviroments[resultProperty] ?? {};
    return (
      <div className={S.contDetails}>
        <p className={S.titleProperty}>{title}</p>
        <p className={`${enviroment?.style} ${S.resultproperty}`}>
          {enviroment?.icon}
          {resultProperty}
        </p>
      </div>
    );
};
