import React from 'react';
import { makeStyles } from '@mui/styles';
import { configApiRef, useApi } from '@backstage/core-plugin-api';

const useStyles = makeStyles({
  img: {
    width: 'auto',
    height: 130,
    maxWidth: '100%',
  },
});

const getImageName = (baseUrl: string, theme: string) => {
  let prefix = '';

  if (baseUrl.includes('dalquimia')) {
    prefix = 'd';
  } else if (baseUrl.includes('halquimia')) {
    prefix = 'h';
  } else if (baseUrl.includes('play')) {
    prefix = 'play-';
  }

  const suffix = theme === 'dark' ? 'dark' : 'light';

  return `${prefix}alquimia-full-${suffix}.svg`;
};

const LogoHome = ({ theme }: any) => {
  const config = useApi(configApiRef);
  const baseUrl: string = config.get('app.baseUrl');
  const classes = useStyles();
  const imageName = getImageName(baseUrl, theme);

  return <img className={classes.img} src={`/Logo/${imageName}`} alt="Home" />;
};

export default LogoHome;
