import { GetDashboardData } from '../../pages/Entity/Contents/Costs/types';

import axios from 'axios';

const getFinopsCostsData = async (
  baseUrl: string,
  componentsQuery: string,
  token?: string,
) => {
  const { data } = await axios.get<GetDashboardData>(
    `${baseUrl}finops/getRealizedCostsData?component_filters=${componentsQuery}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    },
  );

  return data;
};

export { getFinopsCostsData };