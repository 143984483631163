import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  links: {
    textDecoration: 'underline',
  },
  text: {
    margin: '0px',
  },
  prefixText: {
    margin: '0px',
    color: '#BDBDBD',
    fontStyle: 'italic',
  },
});
