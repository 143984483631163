import React from 'react';

import CircleIcon from '@mui/icons-material/Circle';

import { useStyles } from './styles';
import * as T from './types';

export const RenderStatus = ({keyText,valueText,coverageOrSync}:T.RenderStatusProps) => { 
  const S = useStyles();
  if(valueText === null || coverageOrSync === undefined){
    return null
  }

  return(
    <div className={S.keyValue}>
    {keyText && <p className={S.key}>{keyText}</p>}
    <div className={S.copybutton}>
        <CircleIcon className={coverageOrSync ?  S.greenCircle : S.redCircle} />
        <p className={S.value}>
          {valueText}
        </p>
    </div>

  </div>
  )
}
