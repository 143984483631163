import React, { useContext, useEffect, useState } from 'react';

import { TimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { ptBR } from 'date-fns/locale/pt-BR';

import DeleteIcon from '@mui/icons-material/Delete';
import AlertTitle from '@mui/material/AlertTitle';
import { useStyles } from './styles';
import * as T from './types';
import { OpsGenieContext } from '../..';
import { UserAutocomplete } from '../../UserAutocomplete';
import { Checkbox, FormControlLabel, IconButton, Link } from '@mui/material';
import { Alert } from '@mui/material';

export function ScheduleStep({ onChange, teamData }: T.ScheduleStepProps) {
  const S = useStyles();
  const isAdmin = useContext(OpsGenieContext).isAdmin;

  const getInitialRotationMembers = () => {
    if (
      teamData?.schedules[0].templateConfig.rotationMembers &&
      teamData?.schedules[0].templateConfig.rotationMembers.length > 0
    ) {
      return teamData?.schedules[0].templateConfig.rotationMembers;
    } else if (teamData?.members && teamData?.members.length > 0) {
      return teamData?.members;
    } else {
      return [];
    }
  };
  const [formData, setFormData] = useState<T.ScheduleFormData>({
    rotationMembers: getInitialRotationMembers(),
    nightShiftStartHour:
      teamData?.schedules[0].templateConfig.nightShiftStartHour || 19,
    notifyAllOnBusinessHours:
      teamData?.schedules[0].templateConfig.notifyAllOnBusinessHours || false,
    startDate:
      teamData?.schedules[0].rotations[0].startDate !== undefined
        ? new Date(teamData?.schedules[0].rotations[0].startDate)
        : undefined,

    isFilled: Boolean(
      teamData?.schedules[0].templateConfig.rotationMembers &&
        teamData?.schedules[0].templateConfig.rotationMembers.length > 0,
    ),
  });

  const removeMember = (member: T.ScheduleMember) => {
    const filteredMembers = formData.rotationMembers.filter(
      user => user.id !== member.id,
    );

    setFormData({
      rotationMembers: filteredMembers,
      nightShiftStartHour: formData.nightShiftStartHour,
      notifyAllOnBusinessHours: Boolean(formData.notifyAllOnBusinessHours),
      startDate: formData.startDate,
      isFilled:
        formData.nightShiftStartHour !== undefined &&
        filteredMembers.length > 0,
    });
  };

  useEffect(() => {
    formData.startDate?.setHours(
      formData.nightShiftStartHour !== undefined
        ? formData.nightShiftStartHour - 12
        : 0,
    );
    onChange({
      rotationMembers: formData.rotationMembers || [],
      nightShiftStartHour: formData.nightShiftStartHour,
      notifyAllOnBusinessHours: Boolean(formData.notifyAllOnBusinessHours),
      startDate: formData.startDate,
      isFilled:
        formData.rotationMembers.length > 0 &&
        formData.nightShiftStartHour !== undefined,
    });
  }, [formData]);

  const handleUserAutocompleteChange = (value: T.ScheduleMember) => {
    if (formData.rotationMembers.find(user => user.id === value.id)) {
      return;
    }

    const currentMembers = [...formData.rotationMembers, value];

    setFormData({
      rotationMembers: currentMembers,
      nightShiftStartHour: formData.nightShiftStartHour,
      notifyAllOnBusinessHours: formData.notifyAllOnBusinessHours,
      startDate: formData.startDate,
      isFilled:
        currentMembers.length > 0 && formData.nightShiftStartHour !== undefined,
    });
  };

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
        <TimePicker
          label="Night shift start"
          views={['hours']}
          value={new Date(0, 0, 0, formData.nightShiftStartHour)}
          onChange={e => {
            setFormData({
              ...formData,
              nightShiftStartHour: e?.getHours(),
            });
          }}
        />
        {isAdmin && (
          <UserAutocomplete
            label="Shift members"
            placeholder="Search team member by email"
            onChange={value => handleUserAutocompleteChange(value)}
            clearValueAfterSelect
          />
        )}
        <div>
          <div className={S.ShiftMembersList}>
            {formData.rotationMembers.map(user => (
              <div className={S.ShiftMember} key={user.id}>
                <p className={S.memberEmail}>{user.email}</p>
                {isAdmin && (
                  <IconButton
                    onClick={() => removeMember(user)}
                    data-testid={`del-${user.id}`}
                  >
                    <DeleteIcon />
                  </IconButton>
                )}
              </div>
            ))}
          </div>
          {formData.rotationMembers.length > 0 && (
            <p className={S.shiftMembers}>
              <strong>{formData.rotationMembers.length}</strong> Member
              {formData.rotationMembers.length > 1 && 's'} added
            </p>
          )}
        </div>
        <FormControlLabel
          control={
            <Checkbox
              name="acceptCosts"
              color="primary"
              checked={formData.notifyAllOnBusinessHours}
              onChange={e =>
                setFormData({
                  ...formData,
                  notifyAllOnBusinessHours: e.target.checked,
                })
              }
            />
          }
          label="Notify all team members during business hours"
        />
        <Alert severity="info">
          <AlertTitle>Info</AlertTitle>
          Este horário será gerado obedecendo à legislação trabalhista, que
          respeita o período máximo de 24 horas entre os turnos.
          <Link
            className={S.alertLink}
            href="https://alquimia.gb.tech/docs/default/component/sre-monitoring-resources/opsgenie/opsgenie/#1-criandoeditando-um-time"
            color="primary"
            target="_blank"
          >
            Clique aqui para saber mais.
          </Link>
        </Alert>
      </LocalizationProvider>
    </>
  );
}
