import { EnvTypesDictionaryType } from './types';

export const EnvTypesDictionary: EnvTypesDictionaryType = {
  aws: {
    logo: { url: '/Images/aws-logo.svg', alt: 'AWS Logo' },
    items: [
      { label: 'Account Id', value: 'spec.aws.accountId' },
      { label: 'Region', value: 'spec.aws.region' },
    ],
  },
  apigee: {
    logo: { url: '/Images/apigee-logo.svg', alt: 'Apigee Logo' },
    items: [
      { label: 'Org', value: 'spec.org' },
      { label: 'ASM Environment', value: 'spec.asmEnvironment' },
    ],
  },
};
