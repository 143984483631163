import React from 'react';

import {
  EntityHasResourcesCard,
  EntityLayout,
} from '@backstage/plugin-catalog';
import { EntityHasApisCard } from '@backstage/plugin-api-docs';
import {
  Direction,
  EntityCatalogGraphCard,
} from '@backstage/plugin-catalog-graph';
import {
  RELATION_PART_OF,
  RELATION_HAS_PART,
  RELATION_API_CONSUMED_BY,
  RELATION_API_PROVIDED_BY,
  RELATION_CONSUMES_API,
  RELATION_PROVIDES_API,
  RELATION_DEPENDENCY_OF,
  RELATION_DEPENDS_ON,
} from '@backstage/catalog-model';

import { Grid } from '@mui/material';

import { EntityWarningContent } from '../Contents/EntityWarning';
import { CostsContent } from '../Contents/Costs';
import { ComponentCard } from '../Contents/ComponentCard';
import { DocumentationsCard } from '../Contents/DocumentationsCard';
import { AboutCard } from '../Contents/AboutCard';

export const SystemPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container alignItems="stretch" spacing={3} >
        {EntityWarningContent}
        <Grid item md={6}>
          <AboutCard />
        </Grid>
        <Grid item md={6}>
          <DocumentationsCard />
        </Grid>
        <Grid item md={12}>
          <ComponentCard />
        </Grid>
        <Grid item md={6}>
          <EntityHasApisCard variant="gridItem" />
        </Grid>
        <Grid item md={6}>
          <EntityHasResourcesCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>
    <EntityLayout.Route path="/diagram" title="Diagram">
      <EntityCatalogGraphCard
        variant="gridItem"
        direction={Direction.TOP_BOTTOM}
        title="System Diagram"
        height={700}
        relations={[
          RELATION_PART_OF,
          RELATION_HAS_PART,
          RELATION_API_CONSUMED_BY,
          RELATION_API_PROVIDED_BY,
          RELATION_CONSUMES_API,
          RELATION_PROVIDES_API,
          RELATION_DEPENDENCY_OF,
          RELATION_DEPENDS_ON,
        ]}
        unidirectional={false}
      />
    </EntityLayout.Route>
    <EntityLayout.Route path="/costs" title="Costs">
      {CostsContent}
    </EntityLayout.Route>
  </EntityLayout>
);
