import { Entity, EntityMeta } from '@backstage/catalog-model';

const NEWRELIC_ENTITY_NAME = 'newrelic/entity-name';

const isNewRelicAvailable = (entity: Entity) => {
  return Boolean(entity?.metadata.annotations?.[NEWRELIC_ENTITY_NAME]);
};

const getMetadataAnnotation = (metadata: EntityMeta, annotation: string) => {
  return metadata.annotations?.[annotation] ?? '';
};

export { isNewRelicAvailable, getMetadataAnnotation };
