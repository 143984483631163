import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => {
  return {
    EditContainer: {
      display: 'flex',
      width: '100%',
      gap: theme.spacing(2),
    },
    Column1: {
      display: 'flex',
      flexDirection: 'column',
      margin: theme.spacing(0.5, 0),
    },
    Column2: {
      display: 'flex',
      flexDirection: 'column',
      gap: '18px',
      flex: 1,
    },
    Item: {
      fontSize: '12px',
    },
    Column3: {
      display: 'flex',
      width: 230,
      flexDirection: 'column',
    },
    MinutesContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(1),
      marginTop: theme.spacing(1),
    },
    Minutes: {
      width: 32,
      height: '24px',
      borderRadius: '4px',
      display: 'flex',
      justifyContent: 'center',
      textAlign: 'center',
      border: `1px solid ${
        theme.palette.mode === 'dark' ? '#FFFFFF33' : '#00000010'
      }`,
      color: theme.palette.text.primary,
      background: theme.palette.mode === 'dark' ? '#FFFFFF1A' : '#00000008',
      margin: '0px',
      '&::placeholder': {
        color: theme.palette.mode === 'dark' ? 'white' : '#808080',
      },
    },
    MinutesLabel: {
      margin: 0,
    },
    SearchUser: {
      marginTop: theme.spacing(2),
    },
    Button: {
      marginTop: '16px',
      width: '50px',
      height: '20px',
      alignSelf: 'flex-end',
    },
    ButtonsWrapper: {
      display: 'flex',
      gap: theme.spacing(1),
      justifyContent: 'flex-end',
    },
  };
});
