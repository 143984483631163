import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  hometheme: {
    backgroundColor: theme.palette.background.default,
  },
  container: {
    margin: theme.spacing(5, 3),
  },
  searchBar: {
    maxWidth: '70vw',
    margin: 'auto',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '5px',
    boxShadow: theme.shadows[1],
  },
  searchBarOutline: {
    borderStyle: 'none',
  },
  callout: {
    borderRadius: 10,
    padding: '0rem 1rem',
    border: `1px solid ${theme.palette.info.dark}`,
    backgroundColor: `${theme.palette.info.dark}40`,
    fontSize: '1rem',
  },
  cont: {
    marginBottom: '5px',
  },
  links: {
    textDecoration: 'underline',
  },
  text: {
    margin: '0px',
  },
}));
