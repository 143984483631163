import React, { useEffect, useState } from 'react';

import { useStyles } from './styles';
import * as T from './types';
import { EscalationRules } from '../../EscalationRules';
import { EscalationRule } from '../../EscalationRules/types';

export function EscalationPoliciesStep({
  onChange,
  teamData,
}: T.EscalationPoliciesStepProps) {
  const S = useStyles();

  const onCallEscalationPath = teamData?.escalationPaths.find(ep =>
    ep.name.endsWith('onCall'),
  );
  const formattedPolicies: T.EscalationPoliciesFormData = {
    escalationRules: onCallEscalationPath?.rules || [],
    isFilled: Boolean(
      onCallEscalationPath?.rules && onCallEscalationPath?.rules.length > 0,
    ),
  };

  const [formData, setFormData] =
    useState<T.EscalationPoliciesFormData>(formattedPolicies);

  const [defaultRule, setDefaultRule] = useState<EscalationRule>();

  const handleEscalationRulesChange = (rules: EscalationRule[]) => {
    setFormData({ ...formData, escalationRules: rules });
  };

  const setDefaultEscalationRule = () => {
    setDefaultRule({
      delay: {
        timeAmount: 0,
        timeUnit: 'minutes',
      },
      condition: 'if-not-acked',
      recipient: {
        type: 'schedule',
        id: 'default',
        email: 'Default Schedule',
      },
      notifyType: 'default',
    });
  };

  const getDefaultEscalationRule = () => {
    const rules = onCallEscalationPath?.rules;

    if (!rules || rules[0]?.recipient.type !== 'schedule') {
      setDefaultEscalationRule();
      return;
    }

    const rule = rules.shift();

    if (rule) {
      rule.recipient.email = 'Default Schedule';
      setDefaultRule(rule);
    }
  };

  useEffect(() => {
    onChange({
      escalationRules: formData.escalationRules,
      isFilled: Boolean(
        formData.escalationRules && formData.escalationRules.length > 0,
      ),
    });
  }, [formData]);

  useEffect(() => {
    getDefaultEscalationRule();
  }, []);

  return (
    <>
      <div>
        <p className={S.EscalationRulesTitle}>Escalation rules</p>
        <EscalationRules
          onChange={value => handleEscalationRulesChange(value)}
          escalationRules={formData.escalationRules}
          defaultRule={defaultRule}
        />
      </div>
    </>
  );
}
