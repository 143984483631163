import React, { useEffect, useState } from 'react';

import { configApiRef, useApi } from '@backstage/core-plugin-api';

import { useStyles } from '../styles';
import * as T from '../types';
import { getChartVersion } from '../../../../../services/api';
import {RenderDetails} from '../../RenderDetails';

export const EnvironmentBase = ({
  result,
  fieldsToRender = [],
  containerImage,
}:T.EnvironmentBaseProps) => {
  const S = useStyles();

  const [chartInfra, setChartInfra] = useState<string | null>(null);
  const [chartApp, setChartApp] = useState<string | null>(null);

  const config = useApi(configApiRef);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const baseUrl: string = config.get('backend.baseUrl');
        const resultInfra = await getChartVersion(baseUrl, 'gb-infra');
        setChartInfra(resultInfra.version);
        const resultApp = await getChartVersion(baseUrl, 'gb-app');
        setChartApp(resultApp.version);
      } catch (e) {
        setChartInfra('conecte-se a VPN');
        setChartApp('conecte-se a VPN');
      }
    };

    fetchData();
  }, []);

  const shouldRenderField = (fieldName: string) => fieldsToRender.includes(fieldName);
  const spec = result?.spec as any;
  const awsSpec = spec?.aws as any;


  return (
    <div className={S.line}>
      {shouldRenderField('description') && <RenderDetails keyValues={[
        { keyText: 'Description', valueText: result?.metadata?.description },
      ]} />}
      {shouldRenderField('org') && <RenderDetails keyValues={[
        { keyText: 'Org', valueText: spec?.org },
      ]} />}
      {shouldRenderField('domain') && <RenderDetails keyValues={[
        { keyText: 'Domain', valueText: spec?.domain },
      ]} />}
      {shouldRenderField('accountId') && <RenderDetails keyValues={[
        { keyText: 'Account Id', valueText: spec?.accountId },
      ]} />}
      {shouldRenderField('trustedAccountKey') && <RenderDetails keyValues={[
        { keyText: 'Trusted Account Key', valueText: spec?.trustedAccountKey },
      ]} />}
      {shouldRenderField('awsAccountId') && <RenderDetails keyValues={[
        { keyText: 'AWS Account Id', valueText: awsSpec?.accountId },
      ]} />}
      {shouldRenderField('region') && <RenderDetails keyValues={[
        { keyText: 'Region', valueText: awsSpec?.region },
      ]} />}
      {shouldRenderField('wafFrontend') && <RenderDetails keyValues={[
        { keyText: 'WAF-Frontend', valueText: awsSpec?.waf?.webAclFrontendDefaultArn, copy: true, truncateText: true },
      ]} />}
      {shouldRenderField('wafBackend') && <RenderDetails keyValues={[
        { keyText: 'WAF-Backend', valueText: awsSpec?.waf?.webAclBackendDefaultArn, copy: true, truncateText: true },
      ]} />}
      {shouldRenderField('oai') && <RenderDetails keyValues={[
        { keyText: 'OAI', valueText: awsSpec?.cloudfront?.oai },
      ]} />}
       {shouldRenderField('chartInfra') && awsSpec?.charts?.infra?.revision && (
        <div className={S.container}>
          <RenderDetails keyValues={[
            { keyText: 'Chart', valueText: awsSpec?.charts?.infra?.name },
            { keyText: 'Chart Version', valueText: awsSpec?.charts?.infra?.revision },
            { keyText: 'Chart newer version', valueText: chartInfra },
          ]} />
        </div>
      )}
      {shouldRenderField('chartApp') && awsSpec?.charts?.app?.revision && (
        <div className={S.container}>
          <RenderDetails keyValues={[
            { keyText: 'Chart', valueText: awsSpec?.charts?.app?.name },
            { keyText: 'Chart Version', valueText: awsSpec?.charts?.app?.revision },
            { keyText: 'Chart newer version', valueText: chartApp },
          ]} />
        </div>
      )}
      {shouldRenderField('eks') && awsSpec?.eks?.clusters?.default?.name && awsSpec?.eks?.clusters?.default?.oidc && (
        <div className={S.container}>
          <RenderDetails keyValues={[
            { keyText: 'EKS Name', valueText: awsSpec?.eks?.clusters?.default?.name },
            {
              keyText: 'OIDC',
              valueText: awsSpec?.eks?.clusters?.default?.oidc,
              copy: true,
              truncateText: true,
            },
          ]} />
        </div>
      )}
      {shouldRenderField('vpcs') && awsSpec?.vpcs?.local?.id && awsSpec?.vpcs?.ram?.id && (
        <div className={S.container}>
          <p>VPCs</p>
          <RenderDetails keyValues={[
            { keyText: 'Local', valueText: String(awsSpec?.vpcs?.local?.id) },
            { keyText: 'RAM', valueText: String(awsSpec?.vpcs?.ram?.id) },
          ]} />
        </div>
      )}
      {shouldRenderField('securityGroups') && awsSpec?.securityGroups?.rdsInstanceMysql && awsSpec?.securityGroups?.rdsInstancePostgres && (
        <div className={S.container}>
          <p>Security Groups</p>
          <RenderDetails keyValues={[
            {
              keyText: 'RDS MySQL',
              valueText: awsSpec?.securityGroups?.rdsInstanceMysql,
            },
            {
              keyText: 'RDS Postgress',
              valueText: awsSpec?.securityGroups?.rdsInstancePostgres,
            },
          ]} />
        </div>
      )}
      {shouldRenderField('container') && containerImage?.ciEnvironment &&
        containerImage?.ciEnvironment != 'undefined' && (
        <div className={S.container}>
          <p>Container Image</p>
          <RenderDetails keyValues={[
            {
              keyText: 'CI Environment',
              valueText: containerImage?.ciEnvironment,
            },
            {
              keyText: 'Build Platform',
              valueText: containerImage?.buildPlatform,
            },
          ]} />
        </div>
      )}
    </div>
  );
};
