import {
  createBaseThemeOptions,
  createUnifiedTheme,
  genPageTheme,
  palettes,
  shapes,
} from '@backstage/theme';

const createTheme = (
  variant: 'light' | 'dark',
  primary: string,
  secondary: string,
  navBar: string,
  pageThemeLeft: string,
  pageThemeRight: string,
) =>
  createUnifiedTheme({
    ...createBaseThemeOptions({
      palette: {
        ...palettes[variant],
        primary: {
          main: primary,
        },
        secondary: {
          main: secondary,
        },
        navigation: {
          background: navBar,
          indicator: '#9BF0E1',
          color: 'white',
          selectedColor: '#FFF',
        },
      },
    }),
    defaultPageTheme: 'home',
    pageTheme: {
      home: genPageTheme({
        colors: [pageThemeLeft, pageThemeRight],
        shape: shapes.wave,
      }),
      documentation: genPageTheme({
        colors: [pageThemeLeft, pageThemeRight],
        shape: shapes.wave2,
      }),
      tool: genPageTheme({
        colors: [pageThemeLeft, pageThemeRight],
        shape: shapes.round,
      }),
      service: genPageTheme({
        colors: [pageThemeLeft, pageThemeRight],
        shape: shapes.wave,
      }),
      website: genPageTheme({
        colors: [pageThemeLeft, pageThemeRight],
        shape: shapes.wave,
      }),
      library: genPageTheme({
        colors: [pageThemeLeft, pageThemeRight],
        shape: shapes.wave,
      }),
      other: genPageTheme({
        colors: [pageThemeLeft, pageThemeRight],
        shape: shapes.wave,
      }),
      app: genPageTheme({
        colors: [pageThemeLeft, pageThemeRight],
        shape: shapes.wave,
      }),
      apis: genPageTheme({
        colors: [pageThemeLeft, pageThemeRight],
        shape: shapes.wave,
      }),
    },
  });


  type ThemeColors = [string, string, string, string, string];

  const themeConfig: Record<string, { light: ThemeColors; dark: ThemeColors }> = {
    dev: {
      light: ['#0288d1','#ffeb3b','#01579b','#0277bd','#4fc3f7'],
      dark: ['#4fc3f7', '#ffeb3b', '#01579b','#0277bd', '#0288d1'],
    },
    hml: {
      light: ['#0097a7','#ff9800','#006064','#00838f','#4dd0e1'],
      dark: ['#4dd0e1','#ff9800','#006064','#00838f','#0097a7']
    },
    play: {
      light: ['#00796b','#ffc107','#004d40','#00695c','#4db6ac'],
      dark: ['#4db6ac','#ffc107','#004d40','#00695c','#00796b',],
    },
  };

  const themes = Object.entries(themeConfig).reduce((acc, [key, value]) => {
    acc[`${key}ThemeLight`] = createTheme('light', ...value.light);
    acc[`${key}ThemeDark`] = createTheme('dark', ...value.dark);
    return acc;
  }, {} as Record<string, ReturnType<typeof createTheme>>);
  
  export const {
    devThemeLight,
    devThemeDark,
    hmlThemeLight,
    hmlThemeDark,
    playThemeLight,
    playThemeDark,
  } = themes

  export { themes };