import React, { useCallback, useState } from 'react';

import {
  discoveryApiRef,
  identityApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import { Alert, Autocomplete, TextField } from '@mui/material';

import { TeamMember } from '../DialogStepper/TeamMembersStep/types';
import { debounce } from 'lodash';
import { getOpsgenieUsersByEmail } from '../../../services/api';

import * as T from './types';
import { useStyles } from './styles';

export function UserAutocomplete({
  onChange,
  clearValueAfterSelect,
  label,
  placeholder,
  size = 'medium',
  className,
  value,
  displayInfo = true,
}: T.UserAutocompleteProps) {
  const S = useStyles();
  const discoveryApi = useApi(discoveryApiRef);
  const identityApi = useApi(identityApiRef);

  const [loading, setLoading] = useState(false);
  const [autocompleteList, setAutocompleteList] = useState<TeamMember[]>([]);
  const [inputValue, setInputValue] = useState<TeamMember>(
    value || { email: '', id: '', name: '' },
  );

  const searchUsers = useCallback(
    debounce(async email => {
      if (email === '') {
        setAutocompleteList([]);
        return;
      }

      try {
        setLoading(true);

        const baseUrl = await discoveryApi.getBaseUrl('');
        const credentials = await identityApi.getCredentials();

        const data = await getOpsgenieUsersByEmail(
          baseUrl,
          email,
          credentials.token,
        );

        setAutocompleteList(data);

        setLoading(false);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }, 350),
    [],
  );

  const handleSelectMember = (member: TeamMember | null) => {
    if (member) {
      onChange(member);

      if (clearValueAfterSelect) {
        setInputValue({ email: '', id: '', name: '' });
      } else {
        setInputValue(member);
      }
    }
  };

  return (
    <Autocomplete
      className={className}
      options={autocompleteList}
      getOptionLabel={option => option.email || option.id}
      loadingText="Loading..."
      noOptionsText="No users found"
      loading={loading}
      onInputChange={(_, changedValue) => searchUsers(changedValue)}
      onChange={(_, changedValue) => handleSelectMember(changedValue)}
      value={inputValue}
      data-testid="user-autocomplete"
      renderInput={params => (
        <div>
          {displayInfo && (
            <Alert severity="info" variant="outlined" className={S.InfoAlert}>
              Certifique-se de que o usuário tenha acesso e seja verificado no
              Opsgenie.
            </Alert>
          )}
          <TextField
            {...params}
            type="email"
            label={label}
            variant="outlined"
            fullWidth
            placeholder={placeholder || 'Search team member by name'}
            size={size}
            helperText="Type user's first name to search for team members"
          />
        </div>
      )}
    />
  );
}
