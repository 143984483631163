import React from "react";

import { EntitySwitch, isKind } from "@backstage/plugin-catalog";

import { DefaultPage } from "./Default";
import { ComponentPage } from "./Component";
import { APIPage } from "./API";
import { DomainPage } from "./DomainPage";
import { GroupPage } from "./Group";
import { SystemPage } from "./SystemPage";
import { UserPage } from "./User";
import { EnvironmentSetPage } from './EnvironmentSetPage';
import { EnvironmentPage } from './EnvironmentPage';
import { InfraDefaultsPage } from "./InfraDefaultsPage";

export const EntityPage = (
  <EntitySwitch>
    <EntitySwitch.Case if={isKind('component')} children={ComponentPage} />
    <EntitySwitch.Case if={isKind('api')} children={APIPage} />
    <EntitySwitch.Case if={isKind('group')} children={GroupPage} />
    <EntitySwitch.Case if={isKind('user')} children={UserPage} />
    <EntitySwitch.Case if={isKind('system')} children={SystemPage} />
    <EntitySwitch.Case if={isKind('domain')} children={DomainPage} />
    <EntitySwitch.Case if={isKind('environmentset') } children={EnvironmentSetPage} />
    <EntitySwitch.Case if={isKind('environment') } children={EnvironmentPage} />
    <EntitySwitch.Case if={isKind('infradefaults') } children={InfraDefaultsPage} />
    <EntitySwitch.Case>
      {DefaultPage}
    </EntitySwitch.Case>
  </EntitySwitch>
)