import React from 'react';

import { EntitySwitch, isComponentType } from '@backstage/plugin-catalog';

import { DefaultPage } from '../Default';
import { ServiceEntityPage } from './ServiceEntity';
import { WebsiteEntityPage } from './WebsiteEntity';

export const ComponentPage = (
  <EntitySwitch>
    <EntitySwitch.Case if={isComponentType('service')}>
      {ServiceEntityPage}
    </EntitySwitch.Case>

    <EntitySwitch.Case if={isComponentType('website')}>
      {WebsiteEntityPage}
    </EntitySwitch.Case>

    <EntitySwitch.Case>{DefaultPage}</EntitySwitch.Case>
  </EntitySwitch>
);
