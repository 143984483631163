/*
 * Copyright 2020 Spotify AB
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, { useContext } from 'react';

import { useApi, configApiRef } from '@backstage/core-plugin-api';
import { SidebarContext } from '@backstage/core-components';

import { useStyles } from './styles';

import { DevIcon, HmlIcon, PlayIcon, AlqIcon } from './LogoIcons';

const LogoFull = () => {
  const { isOpen } = useContext(SidebarContext);

  const classes = useStyles();

  const config = useApi(configApiRef);
  const baseUrl: string = config.get('app.baseUrl');

  if (baseUrl.includes('dalquimia')) {
    return (
      <DevIcon
        className={`${isOpen ? classes.iconExpanded : classes.iconDefault} ${classes.icon}`}
      />
    );
  } else if (baseUrl.includes('halquimia')) {
    return (
      <HmlIcon
        className={`${isOpen ? classes.iconExpanded : classes.iconDefault} ${classes.icon}`}
      />
    );
  } else if (baseUrl.includes('play')) {
    return (
      <PlayIcon
        className={`${isOpen ? classes.iconExpanded : classes.iconDefault} ${classes.icon}`}
      />
    );
  } else {
    return (
      <AlqIcon
        className={`${isOpen ? classes.iconExpanded : classes.iconDefault} ${classes.icon}`}
      />
    );
  }
};

export default LogoFull;
