import { DEFAULT_NAMESPACE, parseEntityRef } from '@backstage/catalog-model';

import axios from 'axios';

const getReadmeData = async (
  token: string | undefined,
  baseUrl: string,
  entityRef: string,
) => {
  const { kind, name, namespace } = parseEntityRef(entityRef, {
    defaultNamespace: DEFAULT_NAMESPACE,
  });

  const response = await axios.get(
    `${baseUrl}readme/${kind}/${namespace}/${name}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    },
  );

  return response;
};

export { getReadmeData };
