import React from 'react';

import { getGithubRepo, getRepositoryApps } from '@grupoboticario/plugin-argo-cd-frontend';

import { Entity } from '@backstage/catalog-model';

import { RenderStatus } from '../RenderStatus';

export const ArgoStatus = ({metadata,spec}:Entity) => {
    const location = metadata?.annotations?.['backstage.io/managed-by-location'] ;
    const argo = metadata?.annotations?.['argocd/app-selector'];
    const domainEntity = spec?.domain
  
    if(!location || !argo || !domainEntity ){
      return null
    }
  
    const { repo } = getGithubRepo(location);
    const appSelector = argo;
  
    const { value } = getRepositoryApps(
      repo,
      appSelector,
      domainEntity as string,
    );
  
    if (!value?.items?.length) {
      return null
    }
  
    const isSync = !value?.items?.some((row: any) => row.status.sync.status !== 'Synced')
  
    return <RenderStatus valueText={isSync? 'synced':'out of sync'} coverageOrSync={isSync}/>
  }
  