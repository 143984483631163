import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => {
  return {
    contEnvSet: {
      padding: '20px',
      borderRadius: '6px',
      backgroundColor: theme.palette.background.paper,
    },
    title: {
      margin: '0px 0px 20px 0px',
    },
    accordion: {
      backgroundColor: theme.palette.background.default,
    },
    accordionSummary: {
      display: 'flex',
      alignItems: 'center',
    },
    summaryHeader: {
      display: 'flex',
      alignItems: 'center',
      gap: '10px',
    },
    summary: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
    },
    launchIcon: {
      padding: '0px',
    },
    envName: {
      fontSize: '14px',
    },
    envStage: {
      margin: '0px',
      padding: '0px',
    },
    githubIcon: {
      padding: '0px',
    },
  };
});
