import React from 'react';

import { EntityLayout } from '@backstage/plugin-catalog';

import { EntityArgoCDContent, isArgocdAvailable } from '@grupoboticario/plugin-argo-cd-frontend';

import { OverviewContent } from '../../Contents/Overview';
import { CICDContent } from '../../Contents/CICD';
import { CostsContent } from '../../Contents/Costs';
import { ReadmePage } from '../../Readme';
import { SecurityContent } from '../../Contents/Security';
import { Entity } from '@backstage/catalog-model';

export const SetupEntity =
  (
  <>
    <EntityLayout.Route path="/" title="Overview">
      {OverviewContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/readme" title="Readme">
      {ReadmePage}
    </EntityLayout.Route>

    <EntityLayout.Route path="/ci-cd" title="CI/CD">
      {CICDContent}
    </EntityLayout.Route>

    (<EntityLayout.Route if={(entity: Entity)=>{
      return isArgocdAvailable(entity);
    }}
       path="/argocd" title="ArgoCD">
      <EntityArgoCDContent />
    </EntityLayout.Route>)

    <EntityLayout.Route path="/costs" title="Costs">
      {CostsContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/security" title="Security">
      {SecurityContent}
    </EntityLayout.Route>
  </>
);

