import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  CardDivider: {
    marginTop: '10px',
  },
  CardContent: {
    padding: '20px',
  },
  CardLinks: {
    display: 'flex',
    flexDirection: 'column',
    padding: '16px',
    borderRadius: '4px',
    backgroundColor: theme.palette.background.default,
    gap: '16px',
  },
  Icon: {
    marginRight: '8px',
    fontSize: '16px',
  },
  Link: {
    display: 'flex',
    alignItems: 'center',
  },

  subheader: {
    fontSize: '14px',
    marginBottom: '16px',
  },
}));
