import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  contDetails: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '40%',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '8px',
    boxShadow: '24px',
  },
  title: {
    padding: '24px 24px 16px 24px',
  },
  containerContent: {
    padding: '16px 24px 24px 24px',
  },
  container: {
    padding: '16px',
    backgroundColor: theme.palette.background.default,
    borderRadius: '4px',
  },
  icon: {
    fontSize: '12px',
  },
  showModal: {
    textDecoration: 'underline',
    fontWeight: 'bold',
  },
}));
