import React from 'react';

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

import lodash from 'lodash';

import * as T from './types';
import { MonthlyCost } from '../types';

export default function CostsTable({ costsData }: T.CostsTableProps) {
  const getTableRows = (data: MonthlyCost[]) => {
    const groupedData = lodash.groupBy(data, 'service');

    const result = lodash.map(groupedData, (serviceData, serviceName) => {
      const costByPeriod = lodash.transform(
        serviceData,
        (acc: Record<string, number>, { period, cost }) => {
          acc[period] = (acc[period] || 0) + cost;
        },
        {},
      );

      return {
        name: serviceName,
        ...costByPeriod,
      };
    });

    return result;
  };

  const rows = getTableRows(costsData.monthlyCosts);

  const columns = [...new Set(costsData.monthlyCosts.map(item => item.period))];

  return (
    <TableContainer component={Paper}>
      <Table size="medium">
        <TableHead>
          <TableRow>
            <TableCell>Service Name</TableCell>
            {columns.map(item => (
              <TableCell key={item}>{item}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map(row => (
            <TableRow key={row.name}>
              <TableCell key={row.name} component="th" scope="row">
                {row.name}
              </TableCell>
              {columns.map(item => (
                <TableCell key={row.name} component="th" scope="row">
                  $ {row[item as keyof typeof row] || 0}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
