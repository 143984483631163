import { ResourcePermission } from '@backstage/plugin-permission-common';

const permission: ResourcePermission = {
  name: 'auto.service.load',
  attributes: {
    action: 'create',
  },
  type: 'resource',
  resourceType: 'catalog-entity',
};

export default permission;
