import React from 'react';

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Chip,
  IconButton,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LaunchIcon from '@mui/icons-material/Launch';

import { useEntity } from '@backstage/plugin-catalog-react';

import { EnvType } from './EnvironmentsConfig/types';
import { useStyles } from './styles';
import { EnvironmentsConfig } from './EnvironmentsConfig';

export const EnvironmentSetCard = () => {
  const S = useStyles();
  const { entity } = useEntity();

  let environments = [entity].flatMap(entity => {
    const envType = entity.spec?.type;
    const envDomain = entity.spec?.domain || '';
    const CIenv =
      envType === 'Kubernetes'
        ? (entity.spec?.ci as any)?.environment
        : undefined;
    const CIbuild =
      envType === 'Kubernetes'
        ? (entity.spec?.ci as any)?.buildPlatform
        : undefined;

    if (Array.isArray(entity.spec?.environments)) {
      return (entity.spec.environments as Array<any>).map(environment => {
        return {
          name: environment.name,
          stage: environment.stage,
          type: envType,
          domain: envDomain,
          ciEnvironment: CIenv,
          ciBuildPlatform: CIbuild,
        };
      });
    } else {
      return [];
    }
  });

  return (
    <div className={S.contEnvSet}>
      <h2 className={S.title}>Environments</h2>
      {environments.map((env, index) => (
        <Accordion key={index} className={S.accordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            className={S.accordionSummary}
          >
            <div className={S.summary}>
              <div className={S.summaryHeader}>
                <IconButton
                  component="a"
                  href={`/catalog/default/environment/${env.name}`}
                  target="_blank"
                  className={S.launchIcon}
                >
                  <LaunchIcon fontSize="small" />
                </IconButton>
                <Typography className={S.envName}>{env.name}</Typography>
                <Chip
                  label={env.stage.toUpperCase()}
                  color="primary"
                  className={S.envStage}
                  size="small"
                />
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <EnvironmentsConfig
              envName={env.name}
              envType={env.type as EnvType}
              ciEnvironment={env.ciEnvironment}
              ciBuildPlatform={env.ciBuildPlatform}
            />
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};
