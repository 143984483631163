import React from 'react';

import { useTheme } from '@mui/material';

import * as T from './types';

export default function BarTotalsLayer(props: Readonly<T.BarTotalsLayerProps>) {
  const labelOffset = 10;
  const labelFontSize = 12;

  const theme = useTheme();

  if (props.bars.length === 0) return null;

  const totals: { [key: string]: number } = {};
  const bandwidth = props.bars[0].width;

  props.bars.forEach(bar => {
    const indexValue = bar.data.indexValue;
    if (!(indexValue in totals)) {
      totals[indexValue] = 0;
    }
    if (!bar.data.hidden) {
      totals[indexValue] += bar.data.value;
    }
  });

  const labels = Object.keys(totals).map(indexValue => {
    const x = props.xScale(indexValue) + bandwidth / 2;
    const y = props.yScale(totals[indexValue]) - labelOffset;
    return (
      <text
        key={'total.' + indexValue}
        x={x}
        y={y}
        textAnchor="middle"
        fontWeight="bold"
        fontSize={labelFontSize}
        fill={theme.palette.text.primary}
      >
        ${totals[indexValue].toFixed(2)}
      </text>
    );
  });

  return <>{labels}</>;
}
