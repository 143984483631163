import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  Container: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    maxWidth: '600px',
  },
  Title: {
    fontSize: '13px',
    fontWeight: 500,
    margin: '0px',
  },
  IntroText: {
    fontSize: '18px',
    fontWeight: 700,
    lineHeight: '26px',
    letterSpacing: '-0.25px',
    margin: '0px',
  },
  CloseIcon: {
    position: 'absolute',
    right: '16px',
    top: '16px',
    cursor: 'pointer',
  },
  Content: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    padding: theme.spacing(2, 0),
  },
  Footer: {
    display: 'flex',
    gap: theme.spacing(2),
  },
  SuccessStepText: {
    color: theme.palette.success.main,
  },
  ErrorStepText: {
    color: theme.palette.error.main,
  },
}));
