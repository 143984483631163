import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  ContextMenu: {
    width: '30px',
    height: '30px',
    left: '854px',
    top: '226px',
  },
  DeleteTeamMenuItem: {
    color: theme.palette.error.main,
    '& svg': {
      fill: theme.palette.error.main,
    },
  },
  MenuWrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0px 8px',

    '& > li': {
      padding: 12,
    },
  },
}));
