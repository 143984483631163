import React from 'react';

const DevIcon = ({ className }) => {
  return (
    <svg
      data-testid="DevIcon"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 1005 236"
    >
      <path
        fill="#fff"
        className="logoText"
        d="M423.506 174.246c-9.434 0-17.894-1.497-25.381-4.492-7.487-3.07-13.851-7.3-19.092-12.691-5.166-5.39-9.134-11.642-11.904-18.754-2.77-7.188-4.155-14.825-4.155-22.911v-4.492c0-9.209 1.31-17.632 3.93-25.268 2.621-7.637 6.364-14.263 11.231-19.878 4.941-5.616 10.931-9.92 17.969-12.915 7.037-3.07 14.973-4.605 23.808-4.605 8.61 0 16.247 1.423 22.91 4.268 6.664 2.845 12.241 6.888 16.734 12.129 4.567 5.24 8.011 11.53 10.332 18.867 2.321 7.262 3.481 15.348 3.481 24.258v13.476h-96.582V99.676h64.8v-2.47c0-4.493-.824-8.499-2.471-12.017-1.572-3.594-3.968-6.44-7.187-8.536-3.22-2.096-7.338-3.144-12.354-3.144-4.267 0-7.936.936-11.006 2.807-3.069 1.872-5.577 4.493-7.524 7.862-1.872 3.369-3.294 7.337-4.268 11.904-.898 4.492-1.347 9.434-1.347 14.824v4.492c0 4.867.674 9.359 2.021 13.477 1.423 4.118 3.407 7.674 5.952 10.669 2.621 2.995 5.765 5.316 9.434 6.963 3.743 1.647 7.974 2.471 12.69 2.471 5.84 0 11.268-1.124 16.285-3.37 5.091-2.321 9.471-5.802 13.139-10.444l15.723 17.07c-2.546 3.669-6.027 7.188-10.445 10.557-4.342 3.369-9.583 6.139-15.722 8.311-6.139 2.096-13.14 3.144-21.001 3.144zm108.037-25.83l26.953-97.93H592.3L551.309 172h-20.44l.674-23.584zm-18.53-97.93l27.065 98.042.562 23.472h-20.552L479.097 50.486h33.916zm78.725 105.904c0-4.792 1.647-8.798 4.942-12.017 3.369-3.219 7.824-4.829 13.364-4.829 5.54 0 9.958 1.61 13.252 4.829 3.369 3.219 5.054 7.225 5.054 12.017 0 4.791-1.685 8.797-5.054 12.016-3.294 3.22-7.712 4.829-13.252 4.829-5.54 0-9.995-1.609-13.364-4.829-3.295-3.219-4.942-7.225-4.942-12.016z"
      />
      <g className="logoIcon">
        <path
          fill="#EA5B26"
          fillRule="evenodd"
          d="M301.393 66.46h-.245c-1.56-7.315-5.179-13.3-10.855-17.956-5.535-4.655-11.921-6.982-19.158-6.982-14.19 0-24.904 5.819-32.141 17.457-7.237 11.638-10.856 28.596-10.856 50.875 0 1.179.01 2.342.03 3.491h32.75V98.632c0-8.313 1.916-14.88 5.748-19.702 3.973-4.988 8.94-7.482 14.899-7.482 5.961 0 10.857 1.746 14.688 5.238 2.499 2.091 4.213 4.709 5.14 7.853V66.461z"
          clipRule="evenodd"
        ></path>
        <path
          fill="#D4D92E"
          fillRule="evenodd"
          d="M301.393 153.539h-.245a37.18 37.18 0 01-4.046 9.986 34.218 34.218 0 01-6.814 7.989c-2.555 2.164-5.537 3.828-8.944 4.993a26.804 26.804 0 01-10.221 1.997c-14.196 0-24.915-5.825-32.155-17.475-6.857-11.192-10.467-27.087-10.83-47.684h32.764v7.989c0 8.322 1.916 14.979 5.749 19.972 3.975 4.827 8.944 7.24 14.906 7.24 5.963 0 10.861-1.664 14.694-4.993 2.5-2.199 4.214-4.891 5.142-8.077v18.063z"
          clipRule="evenodd"
        ></path>
        <path
          fill="#8DBF43"
          d="M334.862 4.921c0-2.75-1.443-4.125-4.328-4.125h-25.102c-2.694 0-4.04 1.375-4.04 4.125v63.996h33.47V4.921z"
        ></path>
        <path
          fill="#3C9AD5"
          d="M301.392 68.918h33.47v108.197c0 1.586-1.096 2.87-2.448 2.87H303.84c-1.352 0-2.448-1.284-2.448-2.87V68.918z"
        ></path>
      </g>
    </svg>
  );
};

export default DevIcon;
