import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  InputText: {
    fontSize: '18px',
    fontWeight: 700,
    lineHeight: '26px',
    margin: '0px',
    padding: '0px',
  },
  EscalationRulesTitle: {
    fontSize: '12px',
    fontWeight: 500,
    margin: theme.spacing(1, 0, 0),
  },
}));
