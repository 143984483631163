import React, { useContext, useState } from 'react';

import { Button, Snackbar } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';

import { useStyles } from './styles';
import * as T from './types';

import Dialog from '@mui/material/Dialog';
import { OpsGenieContext } from '..';
import {
  configApiRef,
  githubAuthApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import {
  deleteOpsgenieTeam,
  getDomainByTeamName,
} from '../../../services/opsgenie';
import { Alert } from '@mui/material';

export function DeleteDialog({ handleClose, open, teamName }: T.ModalProps) {
  const S = useStyles();
  const githubAuth = useApi(githubAuthApiRef);
  const config = useApi(configApiRef);
  const githubOrg: string = config.get('github.orgName');
  const isAdmin = useContext(OpsGenieContext).isAdmin;
  const domainList = useContext(OpsGenieContext).domainList;

  const [result, setResult] = useState({
    open: false,
    error: false,
    message: '',
  });

  const closeDialog = () => {
    handleClose();
  };

  const handleSanckBarClose = (
    _event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setResult({ ...result, open: false });
  };

  const submitDelete = async () => {
    setResult({ ...result, open: false });

    try {
      const githubToken = await githubAuth.getAccessToken([
        'repo',
        'repo:status',
        'repo_deployment',
        'public_repo',
        'repo:invite',
        'security_events',
      ]);

      await deleteOpsgenieTeam(
        githubOrg,
        teamName,
        getDomainByTeamName(domainList, teamName),
        githubToken,
      );
      setResult({
        open: true,
        error: false,
        message: 'Team excluído com sucesso.',
      });
      closeDialog();
    } catch (error) {
      console.error(error);
      setResult({
        open: true,
        error: true,
        message: 'Erro ao excluir o team. Por favor, tente novamente.',
      });
    }
  };

  return (
    <>
      <Dialog onClose={closeDialog} open={open} maxWidth="sm" fullWidth>
        <div className={S.Container}>
          <header>
            <h2 className={S.Title}>Delete team</h2>
            <p className={S.DeletedTeamName}>{teamName}</p>
            <CloseIcon
              data-testid="close-button"
              onClick={closeDialog}
              className={S.CloseIcon}
            />
          </header>
          <div className={S.Container}>
            <Alert severity="warning" variant="outlined" sx={{ width: '100%' }}>
              WARNING
            </Alert>
            <p>
              This action will permanently delete the team <b>{teamName}</b>.
            </p>
            <p>Are you sure you want to proceed?</p>
          </div>
          {isAdmin && (
            <footer className={S.Footer}>
              {
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={submitDelete}
                >
                  Confirm
                </Button>
              }
              {
                <Button
                  variant="contained"
                  color="primary"
                  onClick={closeDialog}
                >
                  Cancel
                </Button>
              }
            </footer>
          )}
        </div>
      </Dialog>
      <Snackbar
        open={result.open}
        autoHideDuration={6000}
        onClose={handleSanckBarClose}
      >
        <Alert
          severity={result.error ? 'error' : 'success'}
          variant="filled"
          sx={{ width: '100%' }}
          onClose={handleSanckBarClose}
        >
          {result.message}
        </Alert>
      </Snackbar>
    </>
  );
}
