export default function validate(catalogInfo: any): Array<string> {
  const errors: Array<string> = Array.from([]);
  if (!catalogInfo) {
    errors.push('CatalogInfo é necessário');
    return errors;
  }
  if (!catalogInfo.domain) {
    errors.push('Domain é necessário');
  }
  if (!catalogInfo.system) {
    errors.push('System é necessário');
  }
  if (!catalogInfo.team) {
    errors.push('Github Team é necessário');
  }
  if (!catalogInfo.repo?.name) {
    errors.push('Name é necessário');
  }
  if (!catalogInfo.componentDescription) {
    errors.push('Description é necessário');
  }
  if (catalogInfo.hasNewRelic && !catalogInfo.newRelicEntityName) {
    errors.push('New Relic entity name é necessário');
  }
  if (catalogInfo.hasArgocd && !catalogInfo.argocdAppSelector) {
    errors.push('Argocd app selector é necessário');
  }
  return errors;
}
