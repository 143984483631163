import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  icon: {
    '&, & > .logoText': {
      transition: 'all 350ms cubic-bezier(.19,.1,.69,.91)',
    },
  },
  iconDefault: {
    width: 220,
    transform: 'translateX(-50px)',
    '& > .logoText': {
      opacity: 0,
    },
    marginLeft: 22,
  },
  iconExpanded: {
    width: 140,
    transform: 'translateX(0px)',
    '& > .logoText': {
      opacity: 1,
    },
    marginLeft: 41,
  },
});
