import React from 'react';

import { useStyles } from './style';
import * as T from './types';

export function ViewState({ rule, disabled = false }: T.ViewStateProps) {
  const S = useStyles();

  const ALERTS: Record<string, string> = {
    'if-not-acked': 'acknowledged',
    'if-not-closed': 'closed',
  };

  const getTimeAmountLabel = (timeAmout: number) => {
    if (timeAmout > 0) {
      return `${timeAmout} min`;
    }
    return 'immediately';
  };

  return (
    <div className={`${S.ViewContainer} ${disabled && 'disabled'}`}>
      <div className={S.ConditionsLine}>
        <p className={S.Condition}>
          <strong>IF</strong>
        </p>
        -
        <p className={S.Alert}>
          Alert is not <strong>{ALERTS[rule.condition]}</strong>
        </p>
      </div>
      <div className={S.ConditionsLine}>
        <p className={S.Action}>
          <strong>THEN</strong>
        </p>
        -
        <p className={S.Alert}>
          <strong>{getTimeAmountLabel(rule.delay.timeAmount)}</strong> after
          creation, notify{' '}
          <strong>{rule.recipient.email?.split('@')[0]}</strong>
        </p>
      </div>
    </div>
  );
}
