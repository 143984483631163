import React from 'react';
import { SidebarItem } from '@backstage/core-components';
import { useStyles } from './styles';
import { useLocation } from 'react-router-dom';

type CustomSidebarItemProps = {
  to: string;
  text: string;
  icon: any;
};

export const CustomSidebarItem = ({
  to,
  text,
  icon,
}: CustomSidebarItemProps) => {
  const S = useStyles();
  const location = useLocation();

  return (
    <SidebarItem
      data-testid={to}
      to={to}
      text={text}
      icon={icon}
      className={location.pathname.includes(`/${to}`) ? S.selectedItem : ''}
    />
  );
};
