import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  cont: {
    display: 'flex', 
    alignItems: 'center'
  },
  icon:{
    marginLeft: 4
  },
  text:{
    fontSize: '14px',
    fontWeight: 'bold',
  }   
});