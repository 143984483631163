import React from 'react';

import { EnvironmentBase } from './EnvironmentBase';
import * as T from './types';

export const Etl = ({ result }:T.ComponentProps) =>  (
  <EnvironmentBase
    result={result} 
    fieldsToRender={['description','awsAccountId','region','chartInfra']}
  />
);

export const Kubernetes = ({
  result,
  ciEnvironment,
  ciBuildPlatform,
}:T.KubernetesProps) => (
  <EnvironmentBase
    result={result} 
    fieldsToRender={['description','awsAccountId','region','wafBackend','eks','vpcs','securityGroups','chartApp','chartInfra']}
    containerImage={{
      ciEnvironment: ciEnvironment || 'undefined',
      buildPlatform: ciBuildPlatform || 'undefined',
    }}
  />
);

export const StaticFrontend = ({ result }:T.ComponentProps) => (
  <EnvironmentBase
    result={result} 
    fieldsToRender={['description','awsAccountId','region','wafFrontend','oai']}
  />
);

export const Serverless = ({ result }:T.ComponentProps) => (
  <EnvironmentBase
    result={result} 
    fieldsToRender={['description','awsAccountId','region','wafBackend','vpcs','securityGroups']}
  />
);

export const Apigee = ({ result }:T.ComponentProps) => (
  <EnvironmentBase 
    result={result} 
    fieldsToRender={['description','org','domain']}
  />
);


export const Aws = ({ result }:T.ComponentProps) => (
  <EnvironmentBase
    result={result} 
    fieldsToRender={['description','awsAccountId','region','wafBackend','eks','vpcs','securityGroups','chartApp','chartInfra']}
  />
);

export const Newrelic = ({ result }:T.ComponentProps) => (
  <EnvironmentBase
    result={result} 
    fieldsToRender={['description','domain','accountId','trustedAccountKey']}
  />
);