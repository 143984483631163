import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  ReviewContainer: {
    overflowY: 'auto',
    maxHeight: 500,
  },
  BackButton: {
    marginRight: theme.spacing(1),
  },
  Footer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'right',
    marginTop: theme.spacing(2),
  },
}));
