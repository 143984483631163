import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  componentName:{
    display: 'flex',
    alignItems: 'center',
  },
  titleColumn:{
    display: 'flex',
    alignItems: 'center' 
  }
});
