import React, { useEffect, useState } from 'react';

import { useApi } from '@backstage/core-plugin-api';
import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import {
  createScaffolderFieldExtension,
  FieldExtensionComponentProps,
} from '@backstage/plugin-scaffolder-react';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { Entity } from '@backstage/catalog-model';

import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';

import EnvironmentCard from './EnvironmentCard';

import { useStyles } from './styles';
import * as T from './types';

export const GBEnvironments = ({
  formContext,
  onChange,
}: FieldExtensionComponentProps<any>) => {
  const S = useStyles();
  const domainStorage = sessionStorage.getItem('Domain');
  const catalogApi = useApi(catalogApiRef);
  const formData = formContext.formData;
  const domain = formData?.catalogInfo?.domain || domainStorage;
  const system = formData?.system;
  const selectedEnvSet = formData?.environmentSet?.metadata.name || '';

  const [entityList, setEntitylist] = useState<Entity[]>([]);

  const getTemplateType = () => {
    const selectedTemplate = window.location.pathname.split('/').pop();

    return entityList.find(
      entity =>
        entity.kind === 'Template' && entity.metadata.name === selectedTemplate,
    )?.spec?.type;
  };

  const getEnvironmentSetsBySystem = (envSetNameList: string[]) => {
    const templateType = getTemplateType();

    const envSets = entityList.filter(
      entity =>
        entity.kind === 'EnvironmentSet' && entity?.spec?.type === templateType,
    );

    const filteredEnvs = envSetNameList.map(envName =>
      envSets.find(env => env.metadata.name === envName),
    );

    return filteredEnvs;
  };

  const getEnvironmentSetsByDomain = () => {
    const templateType = getTemplateType();

    const filteredEnvs = entityList.filter(
      entity =>
        entity.kind === 'EnvironmentSet' &&
        entity?.spec?.domain === domain &&
        entity?.spec?.type === templateType &&
        entity?.spec?.enabled === true,
    );

    return filteredEnvs;
  };

  const getEnvironmentSets = () => {
    const systemData = entityList.find(
      entity => entity.kind === 'System' && entity?.metadata?.name === system,
    );

    let filteredEnvSets;

    if (systemData?.spec?.environmentSets) {
      filteredEnvSets = getEnvironmentSetsBySystem(
        systemData.spec.environmentSets as string[],
      );
    } else {
      filteredEnvSets = getEnvironmentSetsByDomain();
    }

    return filteredEnvSets as Entity[];
  };

  const getAllEntities = () => {
    const request = {
      filter: {
        kind: ['System', 'Template', 'EnvironmentSet', 'Environment'],
      },
    };

    catalogApi.getEntities(request).then(response => {
      setEntitylist(response.items);
    });
  };

  const getEnvironmentSetByName = (envSetName: string) => {
    return entityList.find(
      entity =>
        entity.kind === 'EnvironmentSet' &&
        entity?.metadata?.name === envSetName,
    );
  };

  const getFilteredEnvironments = (envs: T.Environment[]) => {
    if (!envs) {
      return [];
    }

    const envsList = envs.map(env => env.name);

    return entityList.filter(
      entity =>
        entity.kind === 'Environment' &&
        envsList.includes(entity.metadata.name),
    );
  };

  const getEnvironments = (envSetName: string) => {
    const selectedEnvSetData = environmentSets.find(
      envSet => envSet?.metadata?.name === selectedEnvSet,
    );

    const envSetData = getEnvironmentSetByName(envSetName);

    const filteredEnvs = getFilteredEnvironments(
      envSetData?.spec?.environments as T.Environment[],
    );

    if (selectedEnvSetData?.spec?.type === 'api-gateway') {
      const asmEnvironment = selectedEnvSetData?.spec?.asmEnvironment;

      filteredEnvs.map(env => {
        if (env.spec) {
          env.spec.asmEnvironment = asmEnvironment;
        }
      });
    }

    return filteredEnvs;
  };

  const environmentSets = getEnvironmentSets();

  const environments = getEnvironments(selectedEnvSet);

  const getFullEnvironmentSetObject = (envSetName: string) => {
    const selectedEnvSetData = environmentSets.find(
      (envSet: Entity) => envSet?.metadata?.name === envSetName,
    );

    if (!selectedEnvSetData) {
      return null;
    }

    const selectedEnvs = selectedEnvSetData?.spec
      ?.environments as T.Environment[];

    const selectedEnvsData = getEnvironments(envSetName);

    const fullEnvs = selectedEnvs?.map(env => {
      const envData = selectedEnvsData.find(
        environment => environment.metadata.name === env.name,
      );

      return { ...envData, ...env };
    });

    return {
      ...selectedEnvSetData,
      spec: { ...selectedEnvSetData?.spec, environments: fullEnvs },
    };
  };

  const setDefaultEnvironmentSet = (envSetList: Entity[]) => {
    if (selectedEnvSet) return;

    const defaultEnvSet = envSetList.find(envSet => envSet?.spec?.isDefault);

    if (defaultEnvSet?.metadata.name && !selectedEnvSet) {
      onChange(getFullEnvironmentSetObject(defaultEnvSet?.metadata.name));
    }
  };

  setDefaultEnvironmentSet(environmentSets);

  useEffect(() => {
    getAllEntities();
  }, []);

  return (
    <>
      <FormControl>
        <InputLabel htmlFor="environmentSet">Environment Set</InputLabel>
        <Select
          data-testid="environmentSetSelect"
          label="Environment Set"
          variant="outlined"
          required
          onChange={e => onChange(getFullEnvironmentSetObject(e.target.value))}
          value={selectedEnvSet}
          className={S.EnvSelect}
          MenuProps={{ classes: { list: S.OptionsWrapper } }}
          inputProps={{ id: 'environmentSet' }}
        >
          {environmentSets.map((envSet, index) => (
            <MenuItem key={index} value={envSet.metadata.name}>
              {envSet.metadata.name}
            </MenuItem>
          ))}
          {environmentSets.length === 0 && (
            <MenuItem disabled value="noEnvSets">
              No EnvironmentSet found
            </MenuItem>
          )}
        </Select>
        <FormHelperText>Select Environment Set</FormHelperText>
      </FormControl>
      {environments.length > 0 && (
        <>
          <h3>Environments</h3>
          <div className={S.CardsWrapper}>
            {environments.map((env, index) => (
              <EnvironmentCard key={index} environment={env} />
            ))}
          </div>
        </>
      )}
    </>
  );
};

export const GBEnvironmentsFieldExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    component: GBEnvironments,
    name: 'GBEnvironments',
  }),
);
