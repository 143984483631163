import {
  createApiFactory,
  createComponentExtension,
  createPlugin,
  createRouteRef,
  discoveryApiRef,
  identityApiRef,
} from '@backstage/core-plugin-api';
import { SreAPIClient, SreAPIRef } from './api';

export const entityContentRouteRef = createRouteRef({
  id: 'sre-entity-content',
});

export const SreCardPlugin = createPlugin({
  id: 'sre-card',
  apis: [
    createApiFactory({
      api: SreAPIRef,
      deps: { discoveryApi: discoveryApiRef, identityApi: identityApiRef },
      factory: ({ discoveryApi, identityApi }) =>
        new SreAPIClient({ discoveryApi, identityApi }),
    }),
  ],
  routes: {
    root: entityContentRouteRef,
  },
});

export const SreCard = SreCardPlugin.provide(
  createComponentExtension({
    name: 'New Relic Card Plugin',
    component: {
      lazy: () => import('./components/SreSummary').then(m => m.default),
    },
  }),
);
