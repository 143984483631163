import axios from 'axios';
import { Entity } from '@backstage/catalog-model';
import { GetEntitiesRequest } from '@backstage/catalog-client';
import { EnvironmentSetEntity } from '../../../types/EnvironmentSetEntity';
import { TargetRelations } from '../../../types/TargetRelations';

const functionsWrapper: any = {
  async getArgoEnvironments(
    baseUrl: string,
    params: { entity: Entity; identityApi: any; config: any; formData: any },
  ) {
    const argoParams: { [key: string]: string | undefined } = {
      selector: params.entity?.metadata.annotations?.['argocd/app-selector'],
    };
    const query = Object.keys(argoParams)
      .filter(key => argoParams[key] !== undefined)
      .map(
        k =>
          `${encodeURIComponent(k)}=${encodeURIComponent(argoParams[k] as string)}`,
      )
      .join('&');
    const auth = await params.identityApi.getCredentials();
    const { data } = await axios.get(
      `${baseUrl}/api/argocd/${params.entity.spec?.domain}/?${query}`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth?.token}`,
        },
      },
    );
    return data.items;
  },
  async getManifests(
    baseUrl: string,
    params: {
      entity: Entity;
      appName: string;
      identityApi: any;
      formData: any;
    },
  ) {
    const auth = await params.identityApi.getCredentials();
    const { data } = await axios.get(
      `${baseUrl}/api/argocd/domain/${params.entity.spec?.domain}/manifest/${params.formData['app-name']}`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth?.token}`,
        },
      },
    );
    const filteredManifests = data.manifests.filter(
      (manifest: any) =>
        manifest.kind ===
        params.formData.autoservice.spec.helmManifestResourceName,
    );
    return filteredManifests;
  },
  async getEnvironmentsFromEnvSet(
    ___: string,
    params: {
      entity: Entity;
      identityApi: any;
      catalogApi: any;
      config: any;
    },
  ) {
    if (!params.entity.spec?.environmentSet) return [];
    const filters: any = {
      'metadata.name': params.entity.spec?.environmentSet,
    };
    const request: GetEntitiesRequest | undefined = {
      filter: {
        kind: ['EnvironmentSet'],
        ...filters,
      },
    };
    const envSetEntityList = await params.catalogApi.getEntities(request);
    const envSetEntity = envSetEntityList.items[0] as EnvironmentSetEntity;
    const relations = envSetEntity.relations as TargetRelations[];
    const environmentsListEntity = relations.filter(
      relation => relation.target.kind === 'environment',
    );
    const environments = environmentsListEntity.map(env => env.target.name);
    return environments;
  },

  async getSubApps(
    ___: string,
    params: {
      entity: Entity;
      identityApi: any;
      catalogApi: any;
      config: any;
    },
  ) {
    const filters: any = {
      'spec.subcomponentOf': `component:${params.entity.metadata.name}`,
    };
    const request: GetEntitiesRequest | undefined = {
      filter: {
        kind: ['Component'],
        ...filters,
      },
    };
    const subAppsList = await params.catalogApi.getEntities(request);
    const appSuffixes: Array<string> = [];
    subAppsList.items.forEach((subApp: Entity) => {
      const subAppTyped = subApp;
      appSuffixes.push(
        subAppTyped.metadata.name.replace(
          `${params.entity.metadata.name}-`,
          '',
        ),
      );
    });
    return appSuffixes;
  },
};

export default async function call(
  baseUrl: string,
  functionName: string,
  params: any,
  config?: any,
) {
  try {
    return await functionsWrapper[functionName](baseUrl, params, config);
  } catch (e) {
    console.error(e);
    return [];
  }
}
