import React, { createContext, useEffect, useState } from 'react';

import { Header, Page, Progress } from '@backstage/core-components';
import {
  ProfileInfo,
  configApiRef,
  discoveryApiRef,
  identityApiRef,
  useApi,
} from '@backstage/core-plugin-api';

import { isUserTechlead, getOpsgenieTeams } from '../../services/api';

import TeamCard from './TeamCard';
import { TeamNotFound } from './TeamNotFound';

import { useStyles } from './styles';
import * as T from './types';
import { catalogApiRef } from '@backstage/plugin-catalog-react';

import { DialogStepper } from './DialogStepper';
import { Button } from '@mui/material';
import { RequestsHistory } from './RequestsHistory';
import { uniqueId } from 'lodash';

export const OpsGenieContext = createContext<T.ContextProps>({
  isAdmin: false,
  domainList: [],
});

export function OnCall() {
  const S = useStyles();

  const catalogApi = useApi(catalogApiRef);
  const identityApi = useApi(identityApiRef);
  const discoveryApi = useApi(discoveryApiRef);
  const config = useApi(configApiRef);

  const githubOrg: string = config.get('github.orgName');

  const [loading, setLoading] = useState(true);
  const [teams, setTeams] = useState<T.OpsgenieTeam[]>([]);
  const [dialog, setDialog] = useState<T.DialogData>({ open: false });

  const [isAdmin, setIsAdmin] = useState(false);
  const [domainList, setDomainList] = useState<string[]>([]);
  const [user, setUser] = useState<ProfileInfo>({});

  const isAdminUser = async () => {
    try {
      const baseUrl = await discoveryApi.getBaseUrl('');
      const credentials = await identityApi.getCredentials();
      const userProfile = await identityApi.getProfileInfo();

      const { isTechlead } = await isUserTechlead(
        baseUrl,
        userProfile.email,
        credentials.token,
      );

      setIsAdmin(isTechlead);
    } catch (error) {
      console.error(error);
    }
  };

  const getDomainList = async () => {
    const request = {
      filter: {
        kind: ['Domain'],
      },
    };

    const { items } = await catalogApi.getEntities(request);
    const domainNames = items.map(item => item.metadata.name);

    setDomainList(domainNames);
  };

  const getTeams = async (email: string) => {
    try {
      const baseUrl = await discoveryApi.getBaseUrl('');
      const credentials = await identityApi.getCredentials();

      const result = await getOpsgenieTeams(baseUrl, email, credentials.token);

      setTeams(result);
    } catch (error) {
      console.error(error);
    }
  };

  const getUserProfileInfo = async () => {
    try {
      const userProfile = await identityApi.getProfileInfo();

      if (userProfile?.email) {
        setUser(userProfile);
        setLoading(true);
        await getTeams(userProfile.email);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const createNewTeam = () => {
    setDialog({ open: true, step: 0 });
  };

  const openDialogStep = (selectedStep: number, teamData: T.OpsgenieTeam) => {
    setDialog({
      open: true,
      step: selectedStep,
      team: teamData,
      stepMarker: uniqueId(),
    });
  };

  useEffect(() => {
    getUserProfileInfo();
    isAdminUser();
    getDomainList();
  }, []);

  if (loading) {
    return <Progress data-testid="progress" />;
  }

  return (
    <OpsGenieContext.Provider value={{ isAdmin, domainList }}>
      <Page themeId="other">
        <Header
          pageTitleOverride="On Call"
          title="On Call"
          subtitle="OpsGenie"
        />

        <div className={S.Container}>
          {teams.length > 0 ? (
            <>
              <div className={S.PageTitleWrapper}>
                <h2>Teams</h2>
                {isAdmin && (
                  <Button variant="contained" onClick={createNewTeam}>
                    Create New Team
                  </Button>
                )}
              </div>
              <div className={S.TeamsContainer}>
                {teams.map(team => (
                  <TeamCard
                    data-testid="team-card"
                    key={team.id}
                    {...team}
                    onEdit={step => openDialogStep(step, team)}
                  />
                ))}
              </div>
            </>
          ) : (
            <TeamNotFound onClick={createNewTeam} />
          )}
          <RequestsHistory
            orgName={githubOrg}
            refreshHistory={() => user?.email && getTeams(user.email)}
          />
        </div>

        <DialogStepper
          team={dialog?.team}
          step={dialog?.step}
          open={dialog.open}
          handleClose={() => setDialog({ ...dialog, open: false })}
          stepMarker={dialog.stepMarker}
        />
      </Page>
    </OpsGenieContext.Provider>
  );
}
