/*
 * Copyright 2022 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import React from 'react';
import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import {
  LayoutTemplate,
  createScaffolderLayout,
} from '@backstage/plugin-scaffolder-react';
import { Grid } from '@mui/material';
import { Secret } from './types';
import { useStyles } from './styles';
import DOMPurify from 'dompurify';

export const SecretsLayoutTemplate: LayoutTemplate = ({
  properties,
  title,
  formData,
  uiSchema,
}) => {
  const S = useStyles();
  const options = 'ui:options';
  const secrets = uiSchema ? (uiSchema[options]?.secrets as Secret[]) : [];
  const isAdvancedMode = uiSchema && String(uiSchema[options]?.advancedMode).toLowerCase() === "true";

  const { prefix, name, suffix } = formData.component;

  const componentName = [prefix, name, suffix].filter(Boolean).join('-');

  return (
    <>
      <h1>{title}</h1>
      <Grid item>
        <h3>IMPORTANT</h3>

        {isAdvancedMode ? (
          <p>
            Make sure to create and fill the secret key/values for <b>{componentName}</b> key in AWS Secret Manager for
            all environments of the VS-related accounts before project creation,
            otherwise, project deployment will fail. The secrets are as follows:
          </p>
        ) : (
          <p>
            Make sure to create and fill the secrets in the AWS Secret Manager for
            all environments of the VS-related accounts before project creation,
            otherwise, project deployment will fail. The secrets are as follows:
          </p>
        )}

        <ul>
          {secrets.map(secret => (
            <li
              className={S.ListItem}
              key={secret.name}
              data-testid={`${formData.component}-${secret.name}`}
            >
              <div className={S.SecretName}>
              {isAdvancedMode ? secret.name: `${componentName}-${secret.name}`}
              </div>
              {secret.title ? (
                <div
                  className={S.SecretTitle}
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(secret.title),
                  }}
                />
              ) : (
                <></>
              )}
              {secret.description ? (
                <div
                  className={S.SecretDescription}
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(secret.description),
                  }}
                />
              ) : (
                <></>
              )}
            </li>
          ))}
        </ul>
      </Grid>
      {properties.map(prop => (
        <Grid item key={prop.content.key}>
          {prop.content}
        </Grid>
      ))}
    </>
  );
};

export const SecretsLayout = scaffolderPlugin.provide(
  createScaffolderLayout({
    name: 'SecretsLayout',
    component: SecretsLayoutTemplate,
  }),
);
