import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  ListItem: {
    'padding-bottom': '10px',
  },
  SecretName: {
    padding: 0,
    'font-weight': 'bold',
  },
  SecretTitle: {
    'font-style': 'italic',
  },
  SecretDescription: {
    'font-size': '0.95em',
    '& > a': {
      'text-decoration': 'underline',
    },
  },
});
