import { AxiosInstance, AxiosRequestConfig } from 'axios';

import axios from 'axios';

export class AxiosHttp {
  public http: AxiosInstance;
  constructor(private baseURL: string, private headers?: any) {
    this.http = axios.create({
      baseURL: baseURL,
      headers: headers,
    });
  }

  public buildOptions(
    method: string,
    api: string,
    data: any = null,
    options: string = '',
  ) {
    const url = `${this.baseURL}${api}${options}`;
    const _options: AxiosRequestConfig = {
      url: url!,
      method,
      headers: this.headers,
    };
    if (data) {
      _options.data = data;
    }
    return _options;
  }
}
