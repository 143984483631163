import React, { useContext, useEffect, useState } from 'react';

import DeleteIcon from '@mui/icons-material/Delete';

import { useStyles } from './styles';

import * as T from './types';

import { IconButton } from '@mui/material';
import { UserAutocomplete } from '../../UserAutocomplete';
import { OpsGenieContext } from '../..';

export function TeamMembersStep({
  onChange,
  members = [],
}: T.TeamMembersStepProps) {
  const S = useStyles();
  const isAdmin = useContext(OpsGenieContext).isAdmin;

  const [stepData, setStepData] = useState<T.TeamMembersFormData>({
    members: members,
    isFilled: members.length > 0,
  });

  const removeMember = (member: T.TeamMember) => {
    const filteredMembers = stepData.members.filter(
      user => user.id !== member.id,
    );

    setStepData({
      members: filteredMembers,
      isFilled: filteredMembers.length > 0,
    });
  };

  const handleUserAutocompleteChange = (value: T.TeamMember) => {
    if (stepData.members.find(user => user.id === value.id)) {
      return;
    }

    setStepData({
      members: [...stepData.members, value],
      isFilled: true,
    });
  };

  useEffect(() => {
    onChange(stepData);
  }, [stepData]);

  return (
    <>
      {isAdmin && (
        <UserAutocomplete
          label="Email"
          placeholder="Search team member by email"
          onChange={value => handleUserAutocompleteChange(value)}
          clearValueAfterSelect
        />
      )}
      <div>
        <div className={S.MembersList}>
          {stepData.members.map(user => (
            <div className={S.Member} key={user.id}>
              <p className={S.Email}>{user.email}</p>
              {isAdmin && (
                <IconButton onClick={() => removeMember(user)}>
                  <DeleteIcon />
                </IconButton>
              )}
            </div>
          ))}
        </div>
        {stepData.members.length > 0 && (
          <p className={S.members}>
            <strong>{stepData.members.length}</strong> Member
            {stepData.members.length > 1 && 's'} added
          </p>
        )}
      </div>
    </>
  );
}
