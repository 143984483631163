import { DiscoveryApi, IdentityApi } from '@backstage/core-plugin-api';
import { ChecksApi } from '.';
import { CheckRequest, CheckResponse } from './types';

export class ChecksApiClient implements ChecksApi {
  private readonly discoveryApi: DiscoveryApi;
  private readonly identityApi: IdentityApi;


  constructor({ discoveryApi, identityApi }: { discoveryApi: DiscoveryApi, identityApi: IdentityApi }) {
    this.discoveryApi = discoveryApi;
    this.identityApi = identityApi;
  }

  async runCheck(request: CheckRequest): Promise<CheckResponse> {
    const proxyBaseUrl = await this.discoveryApi.getBaseUrl('proxy');
    const creds = await this.identityApi.getCredentials();
    const response = await fetch(
      `${proxyBaseUrl}/alquimia-checks/check`,
      {
        method: 'POST',
        body: JSON.stringify(request),
        headers: {
          Authorization: `Bearer ${creds.token}`,
        },
      },
    );
    const data = await response.json();
    return data as CheckResponse;
  }
}