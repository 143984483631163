import React from 'react';

const HmlIcon = ({ className }) => {
  return (
    <svg
      data-testid="HmlIcon"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 1005 236"
    >
      <path
        fill="#fff"
        className="logoText"
        d="M406.336 84.9V180h-32.033V60.071h30.038l1.995 24.828zm-4.434 30.924h-8.867c0-8.35.998-16.035 2.993-23.055 2.069-7.094 5.062-13.227 8.978-18.4 3.99-5.246 8.904-9.31 14.742-12.192 5.837-2.882 12.598-4.322 20.283-4.322 5.321 0 10.198.812 14.631 2.438 4.434 1.552 8.239 4.027 11.417 7.426 3.251 3.325 5.763 7.685 7.537 13.08 1.773 5.32 2.66 11.711 2.66 19.175V180h-31.922v-76.479c0-5.469-.739-9.68-2.217-12.636-1.478-2.956-3.584-5.025-6.318-6.207-2.66-1.183-5.874-1.774-9.643-1.774-4.138 0-7.759.85-10.862 2.55-3.03 1.7-5.542 4.064-7.537 7.093-1.995 2.956-3.473 6.43-4.434 10.419-.96 3.991-1.441 8.276-1.441 12.858zm71.381-3.658l-11.416 1.441c0-7.833.96-15.111 2.882-21.835 1.995-6.725 4.913-12.636 8.756-17.735 3.916-5.099 8.756-9.052 14.52-11.86 5.764-2.882 12.414-4.322 19.951-4.322 5.764 0 11.01.85 15.739 2.549 4.729 1.625 8.757 4.249 12.082 7.87 3.399 3.546 5.985 8.202 7.759 13.965 1.847 5.764 2.771 12.82 2.771 21.171V180h-32.033v-76.59c0-5.542-.739-9.754-2.217-12.636-1.404-2.956-3.473-4.988-6.207-6.096-2.66-1.183-5.837-1.774-9.532-1.774-3.843 0-7.205.776-10.087 2.328-2.881 1.478-5.283 3.547-7.204 6.207-1.921 2.66-3.362 5.764-4.323 9.311-.96 3.472-1.441 7.278-1.441 11.416zM604.739 9.75V180h-32.032V9.75h32.032zm27.932 154.843c0-4.729 1.626-8.682 4.877-11.86 3.325-3.177 7.722-4.766 13.19-4.766 5.468 0 9.828 1.589 13.079 4.766 3.325 3.178 4.988 7.131 4.988 11.86 0 4.729-1.663 8.683-4.988 11.86-3.251 3.178-7.611 4.766-13.079 4.766-5.468 0-9.865-1.588-13.19-4.766-3.251-3.177-4.877-7.131-4.877-11.86z"
      />
      <g className="logoIcon">
        <path
          fill="#EA5B26"
          d="M278.2 8.36c0-3.573-1.39-5.36-4.17-5.36h-24.187c-2.596 0-3.893 1.787-3.893 5.36V91.5h32.25V8.36z"
        ></path>
        <path
          fill="#D4D92E"
          d="M245.95 91.5h32.25v86.213c0 1.263-1.056 2.287-2.358 2.287h-27.533c-1.303 0-2.359-1.024-2.359-2.287V91.5z"
        ></path>
        <path
          fill="#3C9AD5"
          d="M317.771 128.375h31.992v50.291c0 .737-1.048 1.334-2.34 1.334H320.11c-1.292 0-2.339-.597-2.339-1.334v-50.291z"
        ></path>
        <path
          fill="#8DBF43"
          fillRule="evenodd"
          d="M278.2 85.047h.239c1.525-6.76 5.061-12.292 10.609-16.594 5.41-4.302 11.651-6.453 18.724-6.453 13.869 0 24.34 5.378 31.413 16.133 7.073 10.755 10.61 26.427 10.61 47.016 0 1.089-.01 2.164-.03 3.226h-32.008v-13.597c0-7.683-1.872-13.752-5.617-18.208-3.883-4.609-8.737-6.913-14.562-6.913-5.825 0-10.61 1.613-14.355 4.84-2.443 1.933-4.117 4.351-5.023 7.258V85.047z"
          clipRule="evenodd"
        ></path>
      </g>
    </svg>
  );
};

export default HmlIcon;
