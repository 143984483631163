export const ANNOTATIONS_ARGOCD_APP_SELECTOR = 'argocd/app-selector';
export const ANNOTATIONS_ARGOCD_HAS_APPS = 'argocd/has-apps';

export type ArgoCDAppDetails = {
  metadata: {
    name: string;
  };
  status: {
    sync: {
      status: string;
    };
    health: {
      status: string;
    };
    operationState: {
      startedAt: string;
      finishedAt?: string;
    };
    history?: {
      id?: number;
      revision?: string;
      deployStartedAt?: string;
      deployedAt?: string;
    }[];
  };
};

export type ArgoCDAppList = {
  items: ArgoCDAppDetails[] | null;
};
