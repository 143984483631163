export const PROVIDED_SERVICES: Record<string, {label: string, service: string}> = {
  "s3": {
    label: "Bucket S3",
    service: "s3 - simple storage service"
  },
  "cloudfront": {
    label: "Cloud Front",
    service: "cloudfront"
  },
  "mq": {
    label: "Rabbit MQ",
    service: "mq"
  },
  "dynamodb": {
    label: "DynamoDB",
    service: "dynamodb"
  },
  "documentdb": {
    label: "DocumentDB",
    service: "documentdb (with mongodb compatibility)"
  },
  "cache": {
    label: "Elasticache",
    service: "elasticache"
  },
  "rds": {
    label: "RDS",
    service: "rds - instância"
  },
  "sns": {
    label: "SNS",
    service: "sns - simple notification service"
  },
  "sqs": {
    label: "SQS",
    service: "sqs - simple queue service"
  },
  "secretsmanager": {
    label: "Secret (ASM)",
    service: "secrets manager"
  },
}