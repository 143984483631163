import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  Summary: {
    padding: theme.spacing(3),
  },
  Title: {
    margin: 0,
    display: 'flex',
    gap: theme.spacing(1),
    '& > div': {
      width: 30,
    },
  },
  ServiceList: {
    maxHeight: 130,
    overflowY: 'auto',
  },
  Service: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(1, 0),
  },
  ServiceText: {
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.text.secondary,
    margin: 0,
  },
  ServiceValue: {
    margin: 0,
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.text.primary,
  },
  Divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
    height: 2,
  },
  TotalCostCard: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    border: '1px solid',
    padding: theme.spacing(2),
    borderColor: theme.palette.divider,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.default,
  },
  TotalCostText: {
    fontSize: 16,
    margin: 0,
  },
  TotalCostValue: {
    fontSize: 24,
    fontWeight: 700,
    margin: 0,
  },
  TotalCostHint: {
    fontSize: 12,
    margin: 0,
    color: theme.palette.text.secondary,
  },
  AcceptCosts: {
    marginTop: theme.spacing(2),
  },
  AcceptCostsLabel: {
    fontSize: 14,
  },
  UnmappedChip: {
    margin: 0,
    letterSpacing: 0.75,
    fontWeight: 'bold',
    padding: theme.spacing(0.5, 1),
    border: '1px solid',
    borderColor: theme.palette.warning.main,
    backgroundColor: `${theme.palette.warning.light}40`,
  },
}));
