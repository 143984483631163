import { IdentityApi, DiscoveryApi } from '@backstage/core-plugin-api';
import { parseJwt, setTokenCookie } from './parse';


// Returns milliseconds until the supplied JWT token expires
export const msUntilExpiry = (token: string): number => {
  const payload = parseJwt(token);
  return new Date(payload.exp * 1000).getTime() - new Date().getTime();
};

export const checkCookie = async (
  result: IdentityApi,
  discoveryApi: DiscoveryApi,
  props: any,
) => {
  const token = (await result.getCredentials()).token;
  if (token) {
    setTokenCookie(await discoveryApi.getBaseUrl('cookie'), token);
  }
  props.onSignInSuccess(result);
};
