import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  Container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    margin: theme.spacing(3),
    width: 'calc(100vw - 72px - 64px)',
  },
  TeamsContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    gap: theme.spacing(2),
  },
  PageTitleWrapper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    '& > button': {
      height: 'max-content',
    },
  },
}));
