import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  contDetails: {
    marginRight: '30px',
  },
  titleProperty: {
    fontSize: '10px',
  },
  resultProperty: {
    fontWeight: 'bold',
    textDecoration: 'underline',
    fontSize: '14px',
  },
  icon: {
    fontSize: '10px',
  },
});
