import React, { useEffect, useState } from 'react';

import { AxiosError } from 'axios';

import {
  discoveryApiRef,
  identityApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import { useEntity } from '@backstage/plugin-catalog-react';
import { InfoCard, Progress } from '@backstage/core-components';

import PlaceholderComponent from '../../../../components/PlaceholderComponent';

import { getFinopsCostsData } from '../../../../services/api';

import CostsChart from './CostsChart';
import CostsTable from './CostsTable';

import * as T from './types';
import { useStyles } from './styles';

export function Costs({ theme }: any): any {
  const S = useStyles();
  const discoveryApi = useApi(discoveryApiRef);
  const identityApi = useApi(identityApiRef);
  const { entity } = useEntity();

  const [costsData, setCostsData] = useState<T.CostsData>();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<AxiosError>();

  const imageName =
    theme === 'dark'
      ? 'dark/data-view-dark-vector.svg'
      : 'light/data-view-vector.svg';

  const entityType = entity.kind.toLocaleLowerCase();

  const entityComponents = entity?.relations?.filter(
    relation => relation.type === 'hasPart',
  ) as T.EntityRelation[];

  const getComponentList = () => {
    const entityName = entity.metadata.name;

    let componentArray: string[];
    if (entityType === 'system') {
      componentArray =
        entityComponents?.map(component => component?.target?.name) || [];
    } else {
      componentArray = [entityName];
    }

    return componentArray.join(',');
  };

  const componentList = getComponentList();

  const getCostsData = async () => {
    setLoading(true);
    try {
      const baseUrl = await discoveryApi.getBaseUrl('');
      const credentials = await identityApi.getCredentials();

      const result = await getFinopsCostsData(
        baseUrl,
        componentList,
        credentials.token,
      );

      return result;
    } catch (err) {
      setError(err);

      if ((window as any)?.newrelic) {
        (window as any).newrelic.noticeError(err);
      }

      return err;
    } finally {
      setLoading(false);
    }
  };

  const getFinopsData = async () => {
    const { data } = await getCostsData();

    setCostsData(data);
  };

  function getDateFilter() {
    const today = new Date();

    const endDate = new Date(today);
    endDate.setDate(today.getDate() - 2);

    const startDate = new Date(today);
    startDate.setMonth(today.getMonth() - 5);
    startDate.setDate(1);

    const formatISODate = (date: Date) => {
      return date.toISOString().split('T')[0];
    };

    return {
      startDate: formatISODate(startDate),
      endDate: formatISODate(endDate),
    };
  }

  const { startDate, endDate } = getDateFilter();

  const REDIRECT_PERIOD = `?period=personalizado&start_date=${startDate}&end_date=${endDate}`;
  const FINOPPING_URL = `https://finopping.grupoboticario.com.br/custos-de-cloud/aws${REDIRECT_PERIOD}`;
  const FINOPPING_CHANNEL = `https://grupoboticario.enterprise.slack.com/archives/C05LMBEV4R5`;

  useEffect(() => {
    getFinopsData();
  }, []);

  if (loading) {
    return <Progress />;
  }

  if (error) {
    const CONTENT = {
      title: 'DADOS INDISPONÍVEIS',
      subtitle: (
        <>
          Dúvidas? Procure o time de finops no canal{' '}
          <a className={S.Link} href={`${FINOPPING_CHANNEL}`} target="_blank">
            #fale-com-finops
          </a>
        </>
      ),
    };

    return (
      <PlaceholderComponent
        srcImage={`/Illustrations/${imageName}`}
        title={CONTENT.title}
        subtitle={CONTENT.subtitle}
      />
    );
  }

  return (
    <InfoCard
      title="Application Costs"
      subheader="*Updated 2 days ago"
      deepLink={{
        link: `${FINOPPING_URL}&filter_des_aplicacao_origem=${componentList}&chart_dimension=tag+app&filter_des_origem_custo=Uso`,
        title: 'More Details On Finopping',
      }}
    >
      {costsData?.monthlyCosts && (
        <>
          <div className={S.Wrapper}>
            <CostsChart costsData={costsData} />
            <div className={S.ChartInfoWrapper}>
              <p className={S.ChartInfo}>
                Total Cost (Last 6 months){' '}
                <span>USD {costsData.totalCost}</span>
              </p>
              <p className={S.ChartInfo}>
                Services{' '}
                <span>{Object.keys(costsData.totalPerService).length}</span>
              </p>
            </div>
          </div>
          <h2>Data view (in USD)</h2>
          <CostsTable costsData={costsData} />
        </>
      )}
    </InfoCard>
  );
}

export const CostsContent = <Costs />;
