import React from 'react';

import { useAsync } from 'react-use';

import { useApi } from '@backstage/core-plugin-api';
import { sonarQubeApiRef, useProjectInfo } from '@backstage/plugin-sonarqube-react';
import { Entity } from '@backstage/catalog-model';

import { RenderStatus } from '../RenderStatus';

export const SonarStatus = ({...entity}:Entity) => {

    const sonarQubeApi = useApi(sonarQubeApiRef);
  
    const { projectKey: projectTitle, projectInstance } = useProjectInfo(entity);

    const { value } = useAsync(
      async () =>
        sonarQubeApi.getFindingSummary({
          componentKey: projectTitle,
          projectInstance: projectInstance,
        }),
      [sonarQubeApi, projectTitle],
    );

    if (!value) {
      return null
    }
    
    const sonarCoverage = Number(value.metrics?.coverage ?? 0 ) 
  
    const isGreaterThan = sonarCoverage >= 80
  
    return <RenderStatus valueText={`${sonarCoverage}%`} coverageOrSync={isGreaterThan}/>
}