import axios from 'axios';

export const getChartVersion = async (
  baseUrl: string,
  chart: string,
) => {
  try {
    const { data } = await axios.get<any>(
      `${baseUrl}/api/proxy/chart-version/${chart}/latest`,
      {
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      },
    );
    return data;
  } catch (error) {
      throw error;
  }
};