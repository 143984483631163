import { makeStyles } from "@material-ui/core";
import { alpha } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  itemDetails: {
    marginRight: '30px',
    maxWidth: '250px', 
  },
  titleProperty: {
    fontSize: '10px',
  },
  resultProperty: {
    marginBottom: '5px',
    fontWeight: 'bold',
    marginRight: '5px',
  },
  list: {
    display: 'flex', 
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: '5px',

    margin:'14px 5px 5px 0',
  },
  listItem: {
    backgroundColor: alpha(theme.palette.primary.main, 0.6),
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '25px',
  },
}));