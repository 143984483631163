import {
  createApiFactory,
  createComponentExtension,
  createPlugin,
  createRouteRef,
  discoveryApiRef,
  identityApiRef,
} from '@backstage/core-plugin-api';

import { NewRelicAPIClient, NewRelicAPIRef } from './api';

export const entityContentRouteRef = createRouteRef({
  id: 'newrelic-entity-content',
});

export const NewrelicCardPlugin = createPlugin({
  id: 'newrelic-card',
  apis: [
    createApiFactory({
      api: NewRelicAPIRef,
      deps: { discoveryApi: discoveryApiRef, identityApi: identityApiRef },
      factory: ({ discoveryApi, identityApi }) =>
        new NewRelicAPIClient({ discoveryApi, identityApi }),
    }),
  ],
  routes: {
    root: entityContentRouteRef,
  },
});

export const NewRelicCard = NewrelicCardPlugin.provide(
  createComponentExtension({
    name: 'New Relic Card Plugin',
    component: {
      lazy: () => import('./components/NewRelicCard').then(m => m.default),
    },
  }),
);
