import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import {
  createScaffolderFieldExtension,
  FieldExtensionComponentProps,
} from '@backstage/plugin-scaffolder-react';
import {
  Card,
  CardContent,
  CardHeader,
  FormControl,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect } from 'react';
import { EnvironmentVariable, StageEnvironmentVariable } from './types';

export const GBTechVariables = ({
  onChange,
  schema: {
    title = 'EventHUB Enviroment Variables',
    description = 'Set up all environments variables',
  },
  required,
  rawErrors,
  formData,
  formContext,
  uiSchema,
}: FieldExtensionComponentProps<StageEnvironmentVariable>) => {
  const options = 'ui:options';
  const environmentVariables =
    (uiSchema[options]?.vars as EnvironmentVariable[]) || [];

  const [envVarsList, setEnvironmentVariable] =
    React.useState<StageEnvironmentVariable>();
  useEffect(() => {
    if (Object.keys(formData).length > 0) {
      setEnvironmentVariable(formData);
    }
  }, [envVarsList]);

  if (environmentVariables.length === 0) {
    return (
      <Typography>
        No Environment Variables were defined to setup on this template
      </Typography>
    );
  }

  return (
    <FormControl data-testid="pubsub-envvars" fullWidth>
      <Typography variant="h6">{title}</Typography>
      <Typography variant="caption">{description}</Typography>
      {formContext.formData.environmentSet.spec.environments.map((env: any) => (
        <>
          <Card elevation={12} square={false}>
            <CardHeader title={env.stage.toUpperCase()} />
            <CardContent>
              {environmentVariables.map((envVar: EnvironmentVariable) => (
                <>
                  <Card square={false}>
                    <CardContent>
                      <TextField
                        role="form"
                        data-testid={`pubsub-envvar-${env.stage}-${envVar.name}`}
                        key={`${env.stage}-${envVar.name}`}
                        label={envVar.title}
                        value={
                          envVarsList &&
                          envVarsList[env.stage] &&
                          envVarsList[env.stage][envVar.name]
                            ? envVarsList[env.stage][envVar.name]?.value
                            : ''
                        }
                        onChange={event => {
                          const fieldValue = envVarsList || {};
                          fieldValue[env.stage] = Object.assign(
                            {},
                            fieldValue[env.stage],
                            {
                              [envVar.name]: {
                                name: envVar.name,
                                title: envVar.title,
                                description: envVar.description,
                                value: event.target.value,
                              },
                            },
                          );
                          setEnvironmentVariable(fieldValue);
                          onChange(fieldValue);
                        }}
                        fullWidth
                        required={required}
                        error={!!rawErrors && rawErrors.length > 0}
                        helperText={rawErrors?.join(', ')}
                      />
                      <Typography variant="caption">
                        {envVar.description}
                      </Typography>
                    </CardContent>
                  </Card>
                  <br />
                </>
              ))}
            </CardContent>
          </Card>
          <br />
        </>
      ))}
    </FormControl>
  );
};

export const GBTechVariablesFieldExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    component: GBTechVariables,
    name: 'GBTechVariables',
  }),
);
