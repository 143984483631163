import React, { useContext } from 'react';
import { useTheme } from '@mui/material';

import PlaceholderComponent from '../../../components/PlaceholderComponent';

import * as T from './types';
import { OpsGenieContext } from '..';

export function TeamNotFound({ onClick }: T.TeamNotFoundProps) {
  const isAdmin = useContext(OpsGenieContext).isAdmin;
  const userType = isAdmin ? 'admin' : 'readOnly';

  const theme = useTheme().palette.mode;

  const imageName =
    theme === 'dark'
      ? 'dark/generic-information-dark-vector.svg'
      : 'light/generic-information-vector.svg';

  const CONTENT = {
    admin: {
      title: 'No team found',
      subtitle: 'There are no teams registered yet',
      buttonText: 'CREATE NEW TEAM',
      buttonLink: '/catalog',
    },
    readOnly: {
      title: 'No team found',
      subtitle: `You haven't been added to a team yet. 
      Please contact your team manager`,
      buttonText: undefined,
      buttonLink: undefined,
    },
  };

  return (
    <PlaceholderComponent
      srcImage={`/Illustrations/${imageName}`}
      title={CONTENT[userType].title}
      subtitle={CONTENT[userType].subtitle}
      buttonText={CONTENT[userType]?.buttonText}
      buttonLink={CONTENT[userType]?.buttonLink}
      onClick={onClick}
    />
  );
}
