import * as loadash from 'lodash';
import type { RJSFValidationError, UiSchema } from '@rjsf/utils/lib';

type UiSchemaComponent = {
  errorMessages: Record<string, string>;
};
const componentSchema = (error: Pick<RJSFValidationError, 'property'>) =>
  error?.property?.replace(/^\.?/, '');

const isSimpleComponent = (component: string) =>
  loadash.lt(loadash.split(component, '.').length, 1);

const uiComponentSchema = (
  uiSchema: UiSchema,
  componentName: string,
): UiSchemaComponent | null => {
  const hasComponentError = loadash.has(uiSchema, componentName);
  if (!hasComponentError) {
    return null;
  }
  const component = isSimpleComponent(componentName)
    ? componentName
    : loadash.split(componentName, '.').slice(0, 1).join('');
  return uiSchema[component]['ui:options'] as UiSchemaComponent;
};

const transformErrors = (
  errors: RJSFValidationError[],
  uiSchema?: unknown,
): RJSFValidationError[] => {
  return errors.map((error: RJSFValidationError) => {
    const component = componentSchema(error);
    const uiSchameComponent = uiComponentSchema(
      uiSchema as UiSchema,
      String(component),
    );
    if (!uiSchameComponent) {
      return error;
    }
    const message =
      uiSchameComponent.errorMessages[String(error.name)] ||
      (error.message as string);
    const newError = { ...error, message, stack: message }; // The empty message is to remove duplication message in simple field in the form
    return newError;
  });
};

export default transformErrors;
