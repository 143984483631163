/*
 * Copyright 2021 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { Entity } from '@backstage/catalog-model';
import {
  Checkbox,
  FormControlLabel,
  TextField,
  Autocomplete,
} from '@mui/material';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, { useEffect, useState } from 'react';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import {
  createScaffolderFieldExtension,
  FieldExtensionComponentProps,
} from '@backstage/plugin-scaffolder-react';
import { useApi } from '@backstage/core-plugin-api';
import { useAsync } from 'react-use';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export const GBTechEntityTagPicker = ({
  onChange,
  schema: { title = 'Tags', description = 'Entity Tags' },
  uiSchema,
  rawErrors,
  formData,
}: FieldExtensionComponentProps<string[]>) => {
  const uiOptions = 'ui:options';
  const multiple = uiSchema[uiOptions]?.multiple as boolean | false;
  const allowNewValue = uiSchema[uiOptions]?.multiple as boolean | false;
  const catalogApi = useApi(catalogApiRef);

  const [selectedTags, setSelectedTags] = useState<string[]>(
    (formData as Array<string>) ?? [],
  );

  const { value: availableTags } = useAsync(async () => {
    const entities = await catalogApi.getEntities({
      fields: ['metadata.tags'],
    });
    const tags = [
      ...new Set(
        entities.items
          .flatMap((e: Entity) => e.metadata?.tags)
          .filter(Boolean) as string[],
      ),
    ].sort();

    return tags ?? [];
  });

  useEffect(() => {
    onChange(selectedTags);
  }, [selectedTags]);

  const onSelect = async (___: any, value: string | string[] | null) => {
    if (typeof value === 'string') {
      setSelectedTags([value]);
    } else if (value) {
      setSelectedTags(value);
    } else {
      setSelectedTags([]);
    }
  };

  return (
    <React.Fragment>
      <Autocomplete
        multiple={multiple}
        freeSolo={allowNewValue}
        aria-label="Tags"
        options={availableTags || []}
        value={selectedTags}
        data-testid="autocomplete-tagsPicker"
        getOptionLabel={value => {
          return typeof value === 'string' ? value : '';
        }}
        onChange={onSelect}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <FormControlLabel
              control={
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  checked={selected}
                />
              }
              label={option}
            />
          </li>
        )}
        popupIcon={<ExpandMoreIcon data-testid="tag-picker-expand" />}
        renderInput={params => (
          <TextField
            {...params}
            data-testid="tag-input"
            label={title}
            helperText={description}
            margin="normal"
            variant="outlined"
            required={selectedTags.length === 0}
            error={rawErrors?.length > 0 && !selectedTags}
          />
        )}
      />
    </React.Fragment>
  );
};

export const GBTechEntityTagPickerFieldExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    component: GBTechEntityTagPicker,
    name: 'GBTechEntityTagPicker',
  }),
);
