import React from 'react';

import {
  EntityConsumedApisCard,
  EntityProvidedApisCard,
} from '@backstage/plugin-api-docs';
import {
  EntityDependsOnComponentsCard,
  EntityDependsOnResourcesCard,
  EntityLayout,
} from '@backstage/plugin-catalog';
import { EntityTechdocsContent } from '@backstage/plugin-techdocs';
import { Grid } from '@mui/material';
import { SetupEntity } from '../SetupEntity';
import { AutoServiceEntityPage } from '@grupoboticario/plugin-auto-service-frontend';
import { usePermission } from '@backstage/plugin-permission-react';
import permission from './permission';
import { useEntity } from '@backstage/plugin-catalog-react';

function ServiceEntityPageComponent() {
  const entity = useEntity().entity;
  const inputPermission = {
    permission: permission,
    resourceRef: `component:${entity.metadata.namespace}/${entity.metadata.name}`,
  };
  const { allowed } = usePermission(inputPermission);

  return (
    <EntityLayout>
      {SetupEntity}
      {allowed && (
        <EntityLayout.Route path="/auto-service" title="Auto Service">
          {AutoServiceEntityPage}
        </EntityLayout.Route>
      )}

      <EntityLayout.Route path="/api" title="API">
        <Grid container spacing={3} alignItems="stretch">
          <Grid item md={6}>
            <EntityProvidedApisCard />
          </Grid>
          <Grid item md={6}>
            <EntityConsumedApisCard />
          </Grid>
        </Grid>
      </EntityLayout.Route>

      <EntityLayout.Route path="/dependencies" title="Dependencies">
        <Grid container spacing={3} alignItems="stretch">
          <Grid item md={6}>
            <EntityDependsOnComponentsCard variant="gridItem" />
          </Grid>
          <Grid item md={6}>
            <EntityDependsOnResourcesCard variant="gridItem" />
          </Grid>
        </Grid>
      </EntityLayout.Route>

      <EntityLayout.Route path="/docs" title="Docs">
        <EntityTechdocsContent />
      </EntityLayout.Route>
    </EntityLayout>
  );
}

export const ServiceEntityPage = <ServiceEntityPageComponent />;
