import { msUntilExpiry } from "./auth";

// Parses supplied JWT token and returns the payload
export const parseJwt = (token: string): { exp: number } => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(c => {
        const currentChar = `00${c.charCodeAt(0).toString(16)}`;
        return `%${currentChar.slice(-2)}`;
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
};

// Calls the specified url regularly using an auth token to set a token cookie
// to authorize regular HTTP requests when loading techdocs
export const setTokenCookie = async (url: string, token: string) => {
  const result = await fetch(url, {
    mode: 'cors',
    credentials: 'include',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (!token) return;

  // If result is not ok, it means that the token is invalid so it does not have to keep calling setTokenCookie
  if (!result.ok) return;

  // Call this function again a few minutes before the token expires
  const ms = msUntilExpiry(token) - 4 * 60 * 1000;
  setTimeout(
    () => {
      setTokenCookie(url, token);
    },
    ms > 0 ? ms : 10000,
  );
};