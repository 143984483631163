import {
  TechRadarApi,
  TechRadarLoaderResponse,
} from '@backstage-community/plugin-tech-radar';

export class TechRadarClient implements TechRadarApi {
  async load(): Promise<TechRadarLoaderResponse> {
    return {
      quadrants: [
        {
          id: '1',
          name: 'Serviços',
        },
        {
          id: '2',
          name: 'Utilitários',
        },
        {
          id: '3',
          name: 'Sistemas',
        },
        {
          id: '4',
          name: 'Plataformas',
        },
      ],
      rings: [
        {
          id: 'N1',
          name: 'N1',
          color: '#93c47d',
        },
        {
          id: 'N2',
          name: 'N2',
          color: '#93d2c2',
        },
        {
          id: 'N3',
          name: 'N3',
          color: '#fbdb84',
        }
      ],
      entries: [
        {
          id: 'typescript',
          title: 'Typescript',
          description: 'Long description for Typescript',
          key: 'typescript',
          url: '#',
          quadrant: '1',
          timeline: [
            {
              moved: 1,
              ringId: 'N1',
              date: new Date('2022-02-08'),
              description: 'Long description for adopt',
            },
            {
              moved: 0,
              ringId: 'N1',
              date: new Date('2022-02-06'),
              description: 'Long description for trial',
            },
          ],
        },
      ],
    };
  }
}
