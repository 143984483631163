import React, { useContext, useEffect, useState } from 'react';

import { Button } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';

import { useStyles } from './styles';
import * as T from './types';

import { TeamInformationStep } from './TeamInformationStep';
import { TeamMembersStep } from './TeamMembersStep';
import { EscalationPoliciesStep } from './EscalationPoliciesStep';
import { SuccessStep } from './SuccessStep';
import { addOrEditOpsgenieTeam } from '../../../services/api';
import { TeamMember } from './TeamMembersStep/types';

import Dialog from '@mui/material/Dialog';
import {
  configApiRef,
  githubAuthApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import { OpsGenieContext } from '..';
import { ErrorStep } from './ErrorStep';
import { ScheduleStep } from './ScheduleStep';
import { getDomainByTeamName } from '../../../services/opsgenie';

export function DialogStepper({
  step = 0,
  handleClose,
  open,
  team,
  stepMarker,
}: T.ModalProps) {
  const S = useStyles();
  const isAdmin = useContext(OpsGenieContext).isAdmin;
  const domainList = useContext(OpsGenieContext).domainList;

  const isEdit = Boolean(team);

  const config = useApi(configApiRef);
  const githubAuth = useApi(githubAuthApiRef);
  const githubOrg: string = config.get('github.orgName');

  const [activeStep, setActiveStep] = useState(step);
  const [formData, setFormData] = useState<T.StepsFormData>({});
  const [error, setError] = useState('');

  const closeDialog = () => {
    handleClose();
    setFormData({});

    if (isEdit) {
      return;
    }

    // ? Delay for the closing animation to finish
    setTimeout(() => {
      setActiveStep(0);
    }, 500);
  };

  const handleForm = (stepData: T.StepsFormData) => {
    setFormData({ ...formData, ...stepData });
  };

  const STEPS = [
    {
      title: 'Create new team (step 1 of 4)',
      introText: 'Team information',
      content: <TeamInformationStep onChange={data => handleForm(data)} />,
      button1: 'Continue',
      button2: 'Cancel',
    },
    {
      title: isEdit ? team?.name : 'Create new team (step 2 of 4)',
      introText: 'Team members',
      content: (
        <TeamMembersStep
          members={team?.members as TeamMember[]}
          onChange={data => handleForm(data)}
        />
      ),
      button1: isEdit ? 'Edit' : 'Continue',
      button2: 'Cancel',
    },
    {
      title: isEdit ? team?.name : 'Create new team (step 3 of 4)',
      introText: 'Schedule',
      content: (
        <ScheduleStep teamData={team} onChange={data => handleForm(data)} />
      ),
      button1: isEdit ? 'Edit' : 'Continue',
      button2: 'Cancel',
    },
    {
      title: isEdit ? team?.name : 'Create new team (step 4 of 4)',
      introText: 'Escalation policies',
      content: (
        <EscalationPoliciesStep
          teamData={team}
          onChange={data => handleForm(data)}
        />
      ),
      button1: isEdit ? 'Edit' : 'Create team',
      button2: 'Cancel',
    },
    {
      title: (
        <strong className={S.SuccessStepText}>
          {isEdit
            ? `Edit team ${team?.name} (completed)`
            : 'Create new team (completed)'}
        </strong>
      ),
      introText: 'You did it!',
      content: <SuccessStep />,
      button1: undefined,
      button2: 'Close',
    },
    {
      title: (
        <strong className={S.ErrorStepText}>
          {isEdit
            ? `Edit team ${team?.name} (error)`
            : 'Create new team (error)'}
        </strong>
      ),
      introText: 'Oops!',
      content: <ErrorStep messsage={error} />,
      button1: undefined,
      button2: 'Close',
    },
  ];

  const createOrEditTeam = async (payload: T.StepsFormData) => {
    try {
      const githubToken = await githubAuth.getAccessToken([
        'repo',
        'repo:status',
        'repo_deployment',
        'public_repo',
        'repo:invite',
        'security_events',
      ]);

      if (payload.name) {
        const domain = await getDomainByTeamName(domainList, payload.name);
        payload.domain ||= domain;
      }

      await addOrEditOpsgenieTeam(githubOrg, payload, githubToken);
      setActiveStep(4);
    } catch (err) {
      setError(err.response.data.message || err.message);
      setActiveStep(5);
      console.error(err);
    }
  };

  const formatEditedTeamData = () => {
    const onCallEscalationPath = team?.escalationPaths.find(ep =>
      ep.name.endsWith('-onCall'),
    );

    const formattedTeam = {
      name: team?.name,
      description: team?.description,
      members: team?.members,
      escalationRules:
        onCallEscalationPath !== undefined ? onCallEscalationPath.rules : [],
      isFilled: true,
      rotationMembers: team?.schedules[0].templateConfig.rotationMembers,
      nightShiftStartHour:
        team?.schedules[0].templateConfig.nightShiftStartHour,
      startDate: new Date(team?.schedules[0].rotations[0].startDate || ''),
      notifyAllOnBusinessHours:
        team?.schedules[0].templateConfig.notifyAllOnBusinessHours || false,
    };

    return {
      ...formattedTeam,
      ...formData,
    };
  };

  const handleNextStep = () => {
    if (isEdit) {
      const payload = formatEditedTeamData();
      createOrEditTeam(payload);

      return;
    }

    if (activeStep === 3) {
      createOrEditTeam(formData);
      return;
    }

    if (activeStep < STEPS.length) {
      setActiveStep(activeStep + 1);
    }
  };

  useEffect(() => {
    setActiveStep(step);
  }, [step, stepMarker]);

  return (
    <>
      <Dialog onClose={closeDialog} open={open} maxWidth="sm" fullWidth>
        <div className={S.Container}>
          <header>
            <h2 className={S.Title}>{STEPS[activeStep].title}</h2>
            <p className={S.IntroText}>{STEPS[activeStep].introText}</p>
            <CloseIcon
              data-testid="close-dialog"
              onClick={closeDialog}
              className={S.CloseIcon}
            />
          </header>
          <form className={S.Content}>{STEPS[activeStep].content}</form>
          {isAdmin && (
            <footer className={S.Footer}>
              {STEPS[activeStep].button1 && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleNextStep}
                  disabled={!formData?.isFilled}
                >
                  {STEPS[activeStep].button1}
                </Button>
              )}
              {STEPS[activeStep].button2 && (
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={closeDialog}
                >
                  {STEPS[activeStep].button2}
                </Button>
              )}
            </footer>
          )}
        </div>
      </Dialog>
    </>
  );
}
