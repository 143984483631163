import React from 'react';

import { useTheme } from '@mui/material';
import { Bar } from '@nivo/bar';

import lodash from 'lodash';

import { MonthlyCost } from '../types';
import { useStyles } from './styles';
import * as T from './types';

import { COLORS } from './colors';

import BarTotalsLayer from './BarTotalsLayer';

export default function CostsChart({ costsData }: T.CostsChartProps) {
  const theme = useTheme();
  const S = useStyles();

  const groupCostsByMonth = (monthlyCostsList: MonthlyCost[]) => {
    return lodash
      .chain(monthlyCostsList)
      .groupBy('period')
      .map((valueList, key) => {
        const costsList: Record<string, number>[] = [];
        valueList.forEach(value => {
          costsList.push({ [value.service]: value.cost });
        });

        return {
          costsList,
          month: key,
        };
      })
      .value();
  };

  const getServiceList = (serviceList: Record<string, number>) => {
    const servicesNameList = Object.keys(serviceList);

    const filteredServiceList = servicesNameList.filter(
      (service: string) => serviceList[service] > 0 && service,
    );

    return filteredServiceList;
  };

  const costsMonths = groupCostsByMonth(costsData.monthlyCosts);

  const serviceList = getServiceList(costsData.totalPerService);

  const simplifiedCostsData = costsMonths.map(item => {
    const { costsList, ...rest } = item;

    const simplifiedCosts = costsList.reduce((acc, cost) => {
      const [key, value] = Object.entries(cost)[0];

      acc[key] = value;
      return acc;
    }, {});

    return { ...rest, ...simplifiedCosts };
  });

  return (
    <Bar
      height={Math.min(window.innerHeight - 350, 420)}
      width={Math.min(window.innerWidth - 180, 1250)}
      data={simplifiedCostsData}
      keys={serviceList}
      indexBy="month"
      margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
      padding={0.3}
      valueScale={{ type: 'linear' }}
      indexScale={{ type: 'band', round: true }}
      layers={[
        'grid',
        'axes',
        'markers',
        'bars',
        'legends',
        'annotations',
        // ? Igonore pois o type BarTotalsLayer não é um layer válido para esta versão do Nivo
        // @ts-ignore - BarTotalsLayer is not a valid layer
        BarTotalsLayer,
      ]}
      colors={d => {
        const key = d.id;
        const index = serviceList.findIndex(value => value === key);
        const color = COLORS[index];
        return color;
      }}
      layout="vertical"
      borderColor={{
        from: 'color',
        modifiers: [['darker', 1.6]],
      }}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: 'Months',
        legendPosition: 'middle',
        legendOffset: 32,
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: 'Cost (USD)',
        legendPosition: 'middle',
        legendOffset: -40,
      }}
      labelSkipWidth={12}
      labelSkipHeight={12}
      legends={[
        {
          dataFrom: 'keys',
          anchor: 'bottom-right',
          direction: 'column',
          justify: false,
          translateX: 120,
          translateY: 0,
          itemsSpacing: 2,
          itemWidth: 100,
          itemHeight: 20,
          itemDirection: 'left-to-right',
          itemOpacity: 0.85,
          symbolSize: 20,
          effects: [
            {
              on: 'hover',
              style: {
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
      theme={{
        labels: {
          text: { fill: theme.palette.grey[900] },
        },
        axis: {
          legend: {
            text: {
              fill: theme.palette.text.primary,
            },
          },
          ticks: {
            text: {
              fill: theme.palette.text.primary,
            },
          },
        },
        legends: {
          text: {
            fill: theme.palette.text.primary,
          },
        },
        text: {
          fill: theme.palette.text.primary,
        },
      }}
      tooltip={tooltip => (
        <p className={S.Tooltip}>
          {tooltip.label}: <b>USD {tooltip.value}</b>
        </p>
      )}
    />
  );
}
