import {
  createApiRef,
  DiscoveryApi,
  IdentityApi,
} from '@backstage/core-plugin-api';

import * as T from '../types';

export interface NewRelicAPI {
  getApplicationInfo(options: {
    url: string;
    projectName: string;
    apmName: string;
  }): Promise<T.NewRelicAPI>;
}

export const NewRelicAPIRef = createApiRef<NewRelicAPI>({
  id: 'plugin.newrelic.service',
});

export type Options = {
  discoveryApi: DiscoveryApi;
  proxyPath?: string;
  identityApi: IdentityApi;
};

export class NewRelicAPIClient implements NewRelicAPI {
  private readonly discoveryApi: DiscoveryApi;
  private readonly identityApi: IdentityApi;

  constructor(options: Options) {
    this.discoveryApi = options.discoveryApi;
    this.identityApi = options.identityApi;
  }

  private async getProxyUrl() {
    return await this.discoveryApi.getBaseUrl('');
  }

  private async fetch(url: string, appName: string, apmName: string) {
    const baseUrl = await this.getProxyUrl();
    const pathArray = url.split('/');
    const domain = pathArray[1];
    const token = (await this.identityApi.getCredentials()).token;
    const response = await fetch(
      `${baseUrl}newrelic/${domain}/${appName}/${apmName}/info`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    if (!response.ok) {
      throw new Error(
        `falha ao buscar data, status ${response.status}: ${response.statusText}`,
      );
    }

    return await response.json();
  }

  async getApplicationInfo(options: {
    url: string;
    projectName: string;
    apmName: string;
  }): Promise<T.NewRelicAPI> {
    return this.fetch(options.url, options.projectName, options.apmName);
  }
}
