import React from 'react';

import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
} from '@mui/material';

import * as T from './types';
import { useStyles } from './styles';
import { EnvTypesDictionary } from './EnvTypesDictionary';

export default function EnvironmentCard({
  environment,
}: T.EnvironmentCardProps) {
  const S = useStyles();
  let envData;

  if (environment?.spec?.type) {
    envData = EnvTypesDictionary[environment.spec.type as T.EnvSetType];
  }

  const getCardHeader = (envName: string) => {
    const envNameSplit = envName.split('-');

    return {
      env: envNameSplit.pop(),
      name: envNameSplit.join(' '),
    };
  };

  const getFieldValue = (field: string) => {
    const keys = field.split('.');
    let value: any = environment;

    for (const key of keys) {
      if (value && typeof value === 'object' && key in value) {
        value = value[key];
      } else {
        return undefined;
      }
    }

    return value;
  };

  const { env, name } = getCardHeader(environment.metadata.name);

  return (
    <Card className={S.Card}>
      <CardHeader
        avatar={
          <div className={S.AvatarWrapper}>
            <img
              className={S.PlatformLogo}
              src={envData?.logo.url}
              alt={envData?.logo.alt}
            />
          </div>
        }
        title={<p className={S.Title}>{name}</p>}
        subheader={<b>{env?.toLocaleUpperCase()}</b>}
      />
      <Divider className={S.Divider} />
      <CardContent>
        {envData?.items?.map((item, index: number) => (
          <Typography
            key={index}
            variant="body2"
            color="textSecondary"
            component="p"
          >
            <b>{item.label}:</b> {getFieldValue(item.value)}
          </Typography>
        ))}
      </CardContent>
    </Card>
  );
}
