import { createApiFactory, createComponentExtension, createPlugin, discoveryApiRef, identityApiRef } from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';
import { checksApiRef } from './api';
import { ChecksApiClient } from './api/client';

export const checksFrontendPlugin = createPlugin({
  id: 'checks-frontend',
  routes: {
    root: rootRouteRef,
  },
  apis: [
    createApiFactory({
      api: checksApiRef,
      deps: { discoveryApi: discoveryApiRef, identityApi: identityApiRef },
      factory: ({ discoveryApi, identityApi }) => new ChecksApiClient({ discoveryApi, identityApi }),
    }),
  ]
});

export const ChecksCard = checksFrontendPlugin.provide(
  createComponentExtension({
    name: 'ChecksCard',
    component: {
      lazy: () => import('./components/Card').then(m => m.default),
    }
  }),
);
