import React from "react";

import { EntityLayout } from "@backstage/plugin-catalog";
import { EntityTechdocsContent } from "@backstage/plugin-techdocs";

import { OverviewContent } from "../Contents/Overview";


export const DefaultPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      {OverviewContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/docs" title="Docs">
      <EntityTechdocsContent />
    </EntityLayout.Route>
  </EntityLayout>
)