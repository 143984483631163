import React, { useCallback, useEffect, useState } from 'react';

import { GetEntitiesRequest } from '@backstage/catalog-client';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import {
  createScaffolderFieldExtension,
  FieldExtensionComponentProps,
} from '@backstage/plugin-scaffolder-react';
import { configApiRef, useApi } from '@backstage/core-plugin-api';

import { FieldValidation } from '@rjsf/utils';

import { FormControl, InputAdornment, TextField, Alert } from '@mui/material';
import { useStyles } from './styles';
import { debounce } from 'lodash';
import {
  ComponentNameParts,
  ComponentErrors,
  Entity,
  EntityPick,
} from './types';

let componentAlreadyExists = false;

export const GBComponentName = ({
  schema,
  uiSchema,
  required,
  onChange,
  formData,
  formContext,
}: FieldExtensionComponentProps<ComponentNameParts>) => {
  const S = useStyles();

  schema.title = schema.title ?? 'Component name';
  schema.description =
    schema.description ?? 'The component name. Pattern: ^[a-z0-9-]{4,}$';

  const config = useApi(configApiRef);
  const baseUrl: string =
    config.get('app.baseUrl') || 'https://alquimia.gb.tech';
  const playgroundDocUrl = `${baseUrl}/docs/default/component/alquimia/backstage/playground/`;

  const playgroundTemplates = [
    'glue',
    'fastify',
    'slack',
    'kotlin',
    'django',
    'typescript',
    'hexagonal',
    'serverless',
    'microfrontend',
    'static',
    'react',
  ];
  const showAlert = playgroundTemplates.some(path =>
    window.location.href.includes(path),
  );

  const catalogApi = useApi(catalogApiRef);
  const uiOptions = 'ui:options';
  const maxLength = Number(uiSchema[uiOptions]?.maxLength || 54);
  const preffixOption = String(uiSchema[uiOptions]?.preffix || '');
  const suffixOption = String(uiSchema[uiOptions]?.suffix || '');
  const pickOption = (uiSchema[uiOptions]?.entityPick as EntityPick) || {};

  const [pick, setPick] = useState<EntityPick>(pickOption);

  const fullPreffix = [preffixOption, pick?.start?.value]
    .filter(Boolean)
    .join('-');
  const fullSuffix = [pick?.end?.value, suffixOption].filter(Boolean).join('-');
  const preffix = fullPreffix ?? formData?.preffix ?? '';
  const suffix = fullSuffix ?? formData?.suffix ?? '';
  const domain = sessionStorage.getItem('Domain') ?? '';
  const [componentStatus, setComponentStatus] = useState<ComponentErrors>({
    status: false,
    message: schema.description,
  });

  const getEntitiesPick = async (
    entity: Entity | undefined,
  ): Promise<Entity | undefined> => {
    if (!entity) {
      return undefined;
    }
    const request = {
      filter: {
        kind: entity.useDomain ? 'Domain' : 'System',
        'metadata.name': entity.useDomain ? domain : '',
      },
      fields: [entity.propertyPath],
    };
    const response = await catalogApi.getEntities(request);
    if (response?.items?.length > 0) {
      const entityCatalog = response.items[0] as any;
      const getValueFromPath = (obj: any, path: string) => {
        for (
          let i = 0, _path = path.split('.'), len = _path.length;
          i < len;
          i++
        ) {
          obj = obj[_path[i]];
        }
        return obj;
      };
      entity.value = getValueFromPath(entityCatalog, entity.propertyPath);
    } else {
      entity.value = '';
    }
    return entity;
  };

  const formatPicks = async () => {
    if ((pick.start || pick.end) && domain) {
      const picks = await Promise.all([
        getEntitiesPick(pick.start),
        getEntitiesPick(pick.end),
      ]);
      const [startPick, endPick] = picks;
      setPick({
        start: startPick,
        end: endPick,
      });
    }
  };

  const [componentName, setComponentName] = useState<string>('');

  const debounceCheckComponentName = useCallback(
    debounce(value => {
      if (!value) {
        onChange({
          preffix: preffix,
          suffix: suffix,
          name: value,
        });
        return;
      }
      const fullName = [preffix, value, suffix].filter(Boolean).join('-');
      if (!isLengthValid(fullName)) {
        onChange(undefined);
        return;
      }
      const request: GetEntitiesRequest | undefined = {
        filter: {
          'metadata.name': fullName,
          kind: 'Component',
        },
      };

      catalogApi.getEntities(request).then(entities => {
        if (entities?.items.length > 0) {
          componentAlreadyExists = true;
          setComponentStatus({
            status: true,
            message: 'A component with the same name already exists',
          });
          onChange(undefined);
        } else {
          onChange({
            preffix: preffix,
            suffix: suffix,
            name: value,
          });
        }
      });
    }, 350),
    [preffix, suffix],
  );

  const checkIfComponentExists = (inputValue: string) => {
    setComponentStatus({ status: false, message: schema.description });
    componentAlreadyExists = false;
    debounceCheckComponentName(inputValue);
  };

  const isLengthValid = (fullName: string) => {
    if (fullName.length > maxLength) {
      setComponentStatus({
        status: true,
        message: `O nome do componente deve ter no máximo ${maxLength} caracteres`,
      });
      return false;
    }
    return true;
  }

  useEffect(() => {
    if (formData && formData.name) {
      checkIfComponentExists(formData.name);
      setComponentName(formData.name);
    }
  }, []);

  useEffect(() => {
    (async () => {
      await formatPicks();
    })();
  }, [formContext.formData.system]);

  useEffect(() => {
    checkIfComponentExists(componentName);
  }, [preffix, suffix, componentName]);

  return (
    <FormControl>
      <TextField
        id="gbtech-componentname-input"
        error={componentStatus?.status}
        label={schema.title}
        onChange={event => setComponentName(event.target.value)}
        margin="normal"
        helperText={componentStatus?.message}
        variant="standard"
        required={required}
        value={componentName ?? ''}
        InputProps={{
          startAdornment: preffix ? (
            <InputAdornment position="start" className={S.prefixText}>
              {preffix}-
            </InputAdornment>
          ) : null,
          endAdornment: suffix ? (
            <InputAdornment position="end">-{suffix}</InputAdornment>
          ) : null,
        }}
      />
      {showAlert && (
        <Alert
          severity="info"
          aria-label="Aviso sobre novo ambiente de testes"
          data-testid="alert-playground"
        >
          <div className={S.text}>
            recomendamos a utilização do{' '}
            <a
              href="https://play.alquimia.gb.tech/"
              target="_blank"
              className={S.links}
            >
              playground
            </a>{' '}
            para criação de componentes de teste, consulte a{' '}
            <a href={playgroundDocUrl} target="_blank" className={S.links}>
              {' '}
              documentação
            </a>
            .
          </div>
        </Alert>
      )}
    </FormControl>
  );
};

export const GBComponentNameFieldExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    component: GBComponentName,
    name: 'GbComponentName',
    validation: (data: any, fieldValidation: FieldValidation) => {
      if (componentAlreadyExists) {
        fieldValidation.addError(
          `Por favor, escolha um nome diferente de: ${data}`,
        );
      }
    },
  }),
);
