import React from 'react';

import { EntityLayout } from '@backstage/plugin-catalog';
import { EntityTeamPullRequestsContent } from '@backstage/plugin-github-pull-requests-board';
import {
  EntityGroupProfileCard,
  EntityMembersListCard,
  EntityOwnershipCard,
} from '@backstage/plugin-org';
import { Grid } from '@mui/material';

import EntityLayoutWrapper from '../../../components/EntityLayoutWrapper';
import { EntityWarningContent } from '../Contents/EntityWarning';

export const GroupPage = (
  <EntityLayoutWrapper>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3}>
        {EntityWarningContent}
        <Grid item xs={12} md={6}>
          <EntityGroupProfileCard variant="gridItem" />
        </Grid>
        <Grid item xs={12} md={6}>
          <EntityOwnershipCard
            variant="gridItem"
            entityFilterKind={['Component', 'API', 'System']}
          />
        </Grid>
        <Grid item xs={12}>
          <EntityMembersListCard />
        </Grid>
      </Grid>
    </EntityLayout.Route>
    <EntityLayout.Route path="/pull-requests" title="Pull Requests">
      <EntityTeamPullRequestsContent />
    </EntityLayout.Route>
  </EntityLayoutWrapper>
);
