import {
  createApiRef,
  DiscoveryApi,
  IdentityApi,
} from '@backstage/core-plugin-api';
import {
  ArgoCDAppDetails,
  ArgoCDAppList,
} from '../types';
import axios, { AxiosInstance } from 'axios';

export interface ArgoCDApi {
  listApps(options: {
    domain: string;
    githubRepo: string;
    appSelector: string;
  }): Promise<ArgoCDAppList>;
  getAppDetails(options: {
    domain: string;
    appName: string;
  }): Promise<ArgoCDAppDetails>;
}

export const argoCDApiRef = createApiRef<ArgoCDApi>({
  id: 'plugin.argocd.service',
});

export type Options = {
  discoveryApi: DiscoveryApi;
  identityApi: IdentityApi;
  proxyPath?: string;
};

export class ArgoCDApiClient implements ArgoCDApi {
  private readonly discoveryApi: DiscoveryApi;
  private readonly identityApi: IdentityApi;
  private readonly axiosInstance: AxiosInstance;

  constructor(options: Options) {
    this.discoveryApi = options.discoveryApi;
    this.identityApi = options.identityApi;
    this.axiosInstance = axios.create();
  }

  private async getProxyUrl() {
    return this.discoveryApi.getBaseUrl('');
  }

  private async request<T>(path: string) {
    this.axiosInstance.defaults.baseURL = await this.getProxyUrl();
    const idToken = await this.identityApi.getCredentials();
    const { data } = await this.axiosInstance.get<T>(path, {
      headers: {
        'Content-Type': 'application/json',
        ...(idToken && { Authorization: `Bearer ${idToken.token}` }),
      },
    });
    return data;
  }

  private formatQueryString(params: { [key: string]: string | undefined }) {
    return Object.keys(params)
      .filter(key => params[key] !== undefined)
      .map(
        k =>
          `${encodeURIComponent(k)}=${encodeURIComponent(params[k] as string)}`,
      )
      .join('&');
  }

  async listApps(options: {
    domain: string;
    githubRepo: string;
    appSelector: string;
  }) {
    try {
      const params: { [key: string]: string | undefined } = {
        github_repo: options.githubRepo
      };
      const query = this.formatQueryString(params);
      const appList = await this.request<ArgoCDAppList>(
        `/argocd/${options.domain}?selector=${query}`
      );
      if (!appList || appList.items === null || appList.items.length === 0) {
        delete params.github_repo;
        params.selector = options.appSelector;
        const queryByAppSelector = this.formatQueryString(params);
        return this.request<ArgoCDAppList>(
          `/argocd/${options.domain}?${queryByAppSelector}`
        );
      }
      return appList;
    } catch (error) {
      console.error('Erro ao obter a lista de apps do Argo: ', error);
      throw error
    }
  }

  async getAppDetails(options: { domain: string; appName: string }) {
    return this.request<ArgoCDAppDetails>(
      `/argocd/${options.domain}/${options.appName}`
    );
  }
}
