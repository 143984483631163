import React from 'react';
import { useEntity } from '@backstage/plugin-catalog-react';
import { useStyles } from './styles';
import {RenderDetails} from '../../Contents/RenderDetails';


export const InfraDefaultsCard = () => {

  const S = useStyles();
  const {entity} = useEntity()

  return (
    <div className={S.contEnv}>
      <h2 className={S.title}>Details</h2>
      <div className={S.infraDetailsCard}>
        <div className={S.line}>
        <RenderDetails keyValues={[
          { keyText: 'uid', valueText: entity?.metadata?.uid, copy: true, truncateText: true },
          { keyText: 'etag', valueText: entity?.metadata?.etag, copy: true, truncateText: true },
          { keyText: 'ingress-scope', valueText: String((entity.spec?.app as any)?.ingress.scope) },
          { keyText: 'replicas', valueText: String((entity.spec?.app as any)?.replicas) },
          { keyText: 'pdb', valueText: String((entity.spec?.app as any)?.pdb?.enabled) },
        ]}/>
        {(entity.spec?.app as any)?.hpa?.enabled &&(
        <div className={S.container}>
          <p>HPA-REPLICAS</p>
          <RenderDetails keyValues={[
            { keyText: 'mínimo', valueText: String((entity.spec?.app as any)?.hpa?.minReplicas) },
            { keyText: 'máximo', valueText: String((entity.spec?.app as any)?.hpa?.maxReplicas) },
          ]} />
        </div>
        )}
        
        <div className={S.container}>
          <p>CACHE</p>
          <RenderDetails keyValues={[
              { keyText: 'db Subnet Group Name', valueText: String((entity.spec?.cache as any)?.dbSubnetGroupName) },
              { keyText: 'clusters', valueText: String((entity.spec?.cache as any)?.numCacheClusters) },
              { keyText: 'memcached-port', valueText: String((entity.spec?.cache as any)?.memcached?.port) },
              { keyText: 'redis-port', valueText: String((entity.spec?.cache as any)?.redis?.port) },
            ]} />
        </div>
        <div className={S.container}>
          <p>DOC DB</p>
          <RenderDetails keyValues={[
              { keyText: 'DB Subnet Group Name', valueText: String((entity.spec?.docdb as any)?.dbSubnetGroupName) },
              { keyText: 'Instances Replicas', valueText: String((entity.spec?.docdb as any)?.instancesReplicas) },
            ]} />
        </div>
        <div className={S.container}>
          <p>EVENTHUB</p>
          <RenderDetails keyValues={[
              { keyText: 'kafka Bootstrap Servers', valueText: String((entity.spec?.eventhub as any)?.kafkaBootstrapServers), copy: true, truncateText: true },
              { keyText: 'schema Registry Url', valueText: String((entity.spec?.eventhub as any)?.schemaRegistryUrl), copy: true, truncateText: true },
            ]} />
        </div>
        <div className={S.container}>
          <p>MQ</p>
          <RenderDetails keyValues={[
              { keyText: 'host Instance Type', valueText: String((entity.spec?.mq as any)?.hostInstanceType) },
              { keyText: 'deployment Mode', valueText: String((entity.spec?.mq as any)?.deploymentMode) },
              { keyText: 'single Instance', valueText: String((entity.spec?.mq as any)?.singleInstance) },
            ]} />
        </div>
        <div className={S.container}>
          <p>RDS</p>
          <RenderDetails keyValues={[
              { keyText: 'DB Subnet Group Name', valueText: String((entity.spec?.rds as any)?.dbSubnetGroupName) },
              { keyText: 'Storage Type', valueText: String((entity.spec?.rds as any)?.storageType) },
              { keyText: 'mysql-port', valueText: String((entity.spec?.rds as any)?.mysql.port) },
              { keyText: 'postgres-port', valueText: String((entity.spec?.rds as any)?.postgres.port) },
            ]} />
        </div>
        </div>
       </div>
    </div>
  );
};