import React, { useEffect, useState } from 'react';

import { catalogApiRef, useEntity, EntityTable } from '@backstage/plugin-catalog-react';
import { useApi } from '@backstage/core-plugin-api';
import { Entity } from '@backstage/catalog-model';

import  Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'

import { SonarStatus } from './SonarStatus';
import { ArgoStatus } from './ArgoStatus';
import { useStyles } from './styles';
import { TextWithTooltip } from '../TextWithTooltip';

export const ComponentCard = () => {
  const S = useStyles();
  const { entity } = useEntity();
  const catalogApi = useApi(catalogApiRef);
  const [entities, setEntities] = useState<Entity[]>([]);

  useEffect(() => {
    const request = {
      filter: {
        kind: 'Component',
        'spec.system': entity.metadata.name,
      },
    };
    async function getCalogEntities() {
      const response = await catalogApi.getEntities(request);
      setEntities(response.items);
    }
    getCalogEntities();
  }, [entity.metadata.name]);


const columns = [
  {
    title: 'Name',
    field: 'metadata.name',
    render: (entity: Entity) => (
      <Link
        href={`/catalog/default/component/${entity.metadata.name}`}
        target="_blank"
        className={S.componentName}
      >
        <Tooltip title={entity.metadata.description || ''}>
          <Typography>{entity.metadata.name}</Typography>
        </Tooltip>
      </Link>
    ),
  },
  { title: 'Owner', field: 'spec.owner' },
  { title: 'Type', field: 'spec.type' },
  { title: 'Lifecycle', field: 'spec.lifecycle' },
  { 
    title: <TextWithTooltip title='Dependabot' tooltipText='PRs abertos pelo dependabot no repositório que ainda não foram revisados'/>, 
    render: (entity: Entity) => {
      const dependabot = (entity.metadata.alquimia as any)?.dependabotBranchCount;
      return dependabot ? `${dependabot} branches abertas` : '';
    }
  },
  { 
    title:  <TextWithTooltip title='Costs (last 6 months)' tooltipText='É necessário estar na VPN para vizualizar esta informação'/>,
    render: (entity: Entity) => {
      const finops = (entity.metadata.alquimia as any)?.finops;
      return finops ? `U$ ${finops.totalCost}` : '';
    }
  },
  {
    title: <TextWithTooltip title='Sonar Coverage' tooltipText='Verifique no catalog-info se a annotation sonarqube.org/project-key esta configurada corretamente'/>,
    render: (entity: Entity) => <SonarStatus {...entity} />,
  },
  {
    title: <TextWithTooltip title=' Argo Sync' tooltipText='É necessário estar na VPN para vizualizar esta informação'/>,
    render: (entity: Entity) => <ArgoStatus {...entity} />,
  },
];

  return (
      <EntityTable
        title="Components"
        entities={entities}
        columns={columns}
        emptyContent={
          <Typography variant="body2">
            Não existem componentes cadastrados neste sistema.
          </Typography>
        }
        tableOptions={{
          paging: true,
          pageSize: 5, 
          pageSizeOptions: [5, 25, 50],
        }}
      />
  );
};