import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  textContainer: {
    maxWidth: '281px',
    [theme.breakpoints.down('sm')]: {
      marginTop: '70px',
      textAlign: 'center',
    },
  },
  image: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));
