import {
  createApiFactory,
  createComponentExtension,
  createPlugin,
  discoveryApiRef,
  identityApiRef,
  microsoftAuthApiRef,
} from '@backstage/core-plugin-api';

import {
  GenericCatalogAPI,
  GenericCatalogAPIClient,
} from './shared/adapters/generic-catalog-api';

import { rootRouteRef } from './routes';

import { createApiRef } from '@backstage/core-plugin-api';

export const SystemCatalogService = createApiRef<GenericCatalogAPI>({
  id: 'plugin.system-catalog.service',
});

// Create a plugin instance and export this from your plugin packages
// Add services used by the plugin
export const SystemCatalogPlugin = createPlugin({
  id: 'system-catalog',
  apis: [
    createApiFactory({
      api: SystemCatalogService,
      deps: {
        discoveryApi: discoveryApiRef,
        identityApi: identityApiRef,
        microsoftAuthApi: microsoftAuthApiRef,
      },
      factory: ({ discoveryApi, identityApi, microsoftAuthApi }) =>
        new GenericCatalogAPIClient(
          { discoveryApi, identityApi, microsoftAuthApi },
        ),
    })
  ],
  routes: {
    root: rootRouteRef,
  },
});

// This creates a routable extension, which are typically full pages of content.
// Each extension should also be exported from your plugin package.
export const SystemCatalogComponent = SystemCatalogPlugin.provide(
  createComponentExtension({
    name: 'System Catalog Plugin',
    component: {
      lazy: () =>
        import('./components/app/SystemCatalog/SystemCatalogListPage').then(
          m => m.SystemCatalogComponent,
        ),
    },
  }),
);

// This creates a routable extension, which are typically full pages of content.
// Each extension should also be exported from your plugin package.
export const SystemCatalogEditComponent = SystemCatalogPlugin.provide(
  createComponentExtension({
    name: 'System Catalog Edit Plugin',
    component: {
      lazy: () =>
        import('./components/app/SystemCatalog/SystemCatalogEditPage').then(
          m => m.SystemCatalogEditComponent,
        ),
    },
  }),
);
