import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { useEntity } from '@backstage/plugin-catalog-react';

import { useStyles } from './styles';
import * as T from './types';
import { Typography } from '@mui/material';
import { Launch } from '@mui/icons-material';

export const ListEnvironment = ({ envType }: { envType: string }) => {
  const { entity } = useEntity();
  const [result, setResult] = useState<string[] | null>(null);
  const S = useStyles();
  const titleCard =
    envType === 'environment' ? 'Environment' : 'Environment Set';

  const getEnvironment = (): string[] => {
    const environmentNamesList = [];

    const relationType =
      envType === 'environment' ? 'hasEnvironment' : 'hasEnvironmentSetFor';

    const envs = entity.relations
      ?.map((relation: T.CustomEntityRelation) => {
        const hasEnvironment =
          relation.type.includes(relationType) && relation.target?.name;
        return hasEnvironment ? relation.target?.name : null;
      })
      .filter((name): name is string => Boolean(name));

    if (envs) {
      environmentNamesList.push(...envs);
    }

    return environmentNamesList;
  };

  useEffect(() => {
    setResult(getEnvironment());
  }, []);

  return result && result.length > 0 ? (
    <div className={S.cont}>
      <h2 className={S.titleCard}>{titleCard}</h2>
      <div className={S.list}>
        {result.map(item => (
          <Link
            key={item}
            to={`/catalog/default/${envType}/${item}`}
            target="_blank"
            className={S.link}
          >
            <div className={S.envItem}>
              <Typography>{item}</Typography>
              <Launch fontSize="small" />
            </div>
          </Link>
        ))}
      </div>
    </div>
  ) : null;
};
