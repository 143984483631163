import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  keyValue: {
    display: 'flex',
    flexDirection: 'column',
    '& > p': {
      margin: 0,
      fontSize: '10px',
    },
  },
  key: {
    textTransform: 'uppercase',
    margin: 0,
  },
  value: {
    fontWeight: 'bold',
    margin: 0,
    fontSize: '12px',
  },
  copybutton: {
    display: 'flex',
  },
  greenCircle: {
    color: 'green',
    fontSize: '16px',
    marginRight: '5px',
  },
  redCircle: {
    color: 'red',
    fontSize: '16px',
    marginRight: '5px',
  },
});
