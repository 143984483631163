import React from 'react';

import Tooltip  from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import * as T from './types';
import { useStyles } from './styles';

export const TextWithTooltip = ({ title, tooltipText }:T.TextWithTooltipProps) => {
  const S = useStyles();
  return (
    <Box className={S.cont}>
      <Typography component="p" className={S.text}>{title}</Typography>
      <Tooltip title={tooltipText} placement="top">
        <InfoIcon className={S.icon} />
      </Tooltip>
    </Box>
  );
};