import { createThemeConfig, getTheme } from './configTheme';
import { Theme } from '@mui/material/styles';

const theme = getTheme(window.location.hostname);

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface (remove this line if you don't have the rule enabled)
  interface DefaultTheme extends Theme {}
}

export const themeConfig = createThemeConfig(theme);
