import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  contAbout: {
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    padding: '20px',
    borderRadius: '6px',
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    fontSize: '24px',
    fontWeight: 'bold',
    margin: '0px 0px 10px 0px',
  },
  propertiesDetails: {
    marginBottom: '25px',
    display: 'flex',
    flexDirection: 'column',
  },
  propertiesDetailsLine: {
    display: 'flex',
    flexDirection: 'row',
  },
  contDetails: {
    marginRight: '30px',
  },
  titleProperty: {
    fontSize: '10px',
  },
  resultProperty: {
    marginBottom: '5px',
    fontWeight: 'bold',
    marginRight: '5px',
  },
  listRunner: {
    fontWeight: 'bold',
    margin: '0px 5px 0px 0px',
    backgroundColor: theme.palette.background.paper,
    padding: '5px',
    borderRadius: '6px',
  },
  contActions: {
    textAlign: 'left',
  },
  buttonAction: {
    margin: '0px 10px 5px 0px',
    fontSize: '12px',
  },
  iconsButton: {
    marginRight: '10px',
    fontSize: '20px',
    padding: '0px',
  },
  buttonIcon: {
    padding: '0px',
    marginRight: '10px',
  },
  itemDetails: {
    marginRight: '30px',
  },
  link: {
    color: theme.palette.primary.main,
  },
  list: {
    display: 'flex',
    flexDirection: 'row',
  },
}));
