import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  CardsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing(3),
    marginBottom: theme.spacing(2),
    flexWrap: 'wrap',
  },
  EnvSelect: {
    maxWidth: 500,
  },
  OptionsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',

    '& > li': {
      padding: theme.spacing(1),
      width: '100%',
      justifyContent: 'flex-start',
    },
  },
}));
