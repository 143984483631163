export const COLORS = [
  '#ba62f5',
  '#ffaa00',
  '#6a85e9',
  '#f897d9',
  '#b3e1af',
  '#d39b70',
  '#e75f41',
  '#fc964d',
  '#39aada',
  '#53944f',
  '#606080',
  '#ec4e98',
  '#bb63f6',
  '#ffb100',
  '#6b86ea',
  '#f898da',
  '#94b591',
  '#d49c71',
  '#e86042',
  '#fd975e',
  '#3aabd9',
  '#6f9c6d',
  '#616181',
  '#c2256e',
  '#4cb5f5',
  '#f26c4f',
  '#69d2e7',
  '#f9d67a',
  '#c4a35a',
  '#8d82b5',
  '#f58686',
  '#5ab9a8',
  '#8d9baf',
  '#e17a5a',
  '#b5e3c9',
  '#ff6f61',
  '#5a5b9f',
  '#ffb4a2',
  '#57bc90',
  '#6a0572',
  '#c3d825',
  '#ff8a5c',
  '#5b9aa0',
  '#b33771',
  '#d7bde2',
  '#ffd666',
  '#355070',
  '#ff7b25',
  '#70dbed',
  '#eb5e55',
  '#a2d729',
  '#99621e',
  '#827191',
  '#feb236',
  '#ff5e78',
  '#add8e6',
  '#fd5e53',
  '#00a896',
  '#1e90ff',
  '#f76f8e',
  '#1e88e5',
  '#ff6f61',
  '#ff7b25',
  '#ffd700',
  '#ff007f',
  '#ff9966',
  '#f08080',
  '#a52a2a',
  '#ff4500',
  '#9370db',
  '#483d8b',
  '#8b4513',
  '#2e8b57',
  '#ff69b4',
  '#9932cc',
  '#8b0000',
  '#5f9ea0',
  '#b22222',
  '#4682b4',
  '#6a5acd',
  '#7fff00',
  '#20b2aa',
  '#808000',
  '#ff6347',
  '#ff8c00',
  '#40e0d0',
  '#2f4f4f',
  '#8b008b',
  '#008080',
];
