import React from 'react';
import { useEntity } from '@backstage/plugin-catalog-react';
import {
  Aws,
  Newrelic,
  Apigee,
} from '../../Contents/EnvironmentCard';
import { useStyles } from './styles';

export const EnvironmentConfigCard = () => {

  const S = useStyles();
  const {entity} = useEntity()
  type EnvType = 'apigee' | 'aws' | 'newrelic';
  const envType: EnvType | undefined = entity.spec?.type as EnvType

  const envTypeToComponent = {
    'apigee': Apigee,
    'aws': Aws,
    'newrelic': Newrelic,
  };

  const renderEnvironmentComponent = () => {
    if (envType) {
      const Component = envTypeToComponent[envType];
      if (Component) {
        return <Component result={entity} />;
      }
    }
    throw new Error(`Environment type '${envType}' não encontrado`);
  };
  

  return (
    <div className={S.contEnv}>
      <h2 className={S.title}>Details</h2>
      {renderEnvironmentComponent()}
    </div>
  );
};
