import React, { useState } from 'react';

import { Modal, Box, Link } from '@mui/material';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';

import { useStyles } from './styles';
import * as T from './types';

export const RenderModal= ({titleLink,children}:React.PropsWithChildren<T.RenderModalProps>) => {
    const S = useStyles();
    const [open, setOpen] = useState(false);
  
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
      <div>
        <Link onClick={handleOpen}
          className={S.showModal}>
            {titleLink}
            <OpenInFullIcon className={S.icon}/>
        </Link>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="checks-card-modal-title"
          aria-describedby="checks-card-modal-description"
        >
          <Box className={S.contDetails}>
            {children}
          </Box>
        </Modal>
      </div>
    );
};