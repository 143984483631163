import React from 'react';

import LaunchIcon from '@mui/icons-material/Launch';
import { Link } from '@mui/material';

import { useStyles } from './styles';
import * as T from './types';

export const RenderLink = ({ title, resultProperty, href }:T.RenderLinkProps) => {
    const S = useStyles();

    return (
      <div className={S.contDetails}>
        <p className={S.titleProperty}>{title}</p>
        <Link href={href} target="_blank" className={S.resultProperty} >
        {resultProperty}
        <LaunchIcon className={S.icon}/>
        </Link>
      </div>
    );
};
